interface RoundableFields {
    weighted_pp?: number,
    average_pp?: number,
    average_sr?: number,
    average_acc?: number,
    average_score?: number
};

export const usePlayerDataRounder = <T extends RoundableFields>(players: T[]): T[] => {
    if (players.length) {
        return players.map(player => {
            const p = { ...player };

            if (p.weighted_pp)
                p.weighted_pp = Math.round(p.weighted_pp);
        
            if (p.average_pp)
                p.average_pp = Math.round(p.average_pp);
        
            if (p.average_sr)
                p.average_sr = Math.round(p.average_sr * 100) / 100;
        
            if (p.average_acc)
                p.average_acc = Math.round(p.average_acc * 10000) / 100;

            if (p.average_score)
                p.average_score = Math.round(p.average_score);

            return p;
        });
    }
    return players.length ? players : [];
};
