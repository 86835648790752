import React, { useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import { useFetch } from '../../../../common/hooks/useAPICall';
import { usePlayerDataRounder } from '../../../../common/hooks/usePlayerDataRounder';
import RankingsTable from '../RankingsTable/RankingsTable';
import { formatNumber, convertGamemodeToString } from '../../../../common/helpers/generic-helpers';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';
import RankingsFilters from '../RankingsFilters/RankingsFilters';
import StyledName from '../../../../common/components/StyledName/StyledName';
import { RankingsPlayerAverageScore } from '../../../../common/interfaces/RankingsPlayer';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const AverageScoreRankings: React.FC<PropsType> = (props) => {
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [searchValue, setSearchValue] = useState<string>('');
    const { response: rankings, loading } = useFetch<RankingsPlayerAverageScore[]>(`rankings/${country.code}/score/average?mode=${gamemodeString}&search=${searchValue}`);
    const parsedRankings = usePlayerDataRounder(rankings);

    if (loading && !rankings)
        return <LoadingIcon />;

    const headers = [
        { key: country.code === "all" ? "rank_average_score_combined" : "rank_average_score", value: "Rank" },
        { key: "name", value: "Name" },
        { key: "average_score_formatted", value: "Average score" },
        { key: "weighted_pp", value: "Weighted pp" },
        { key: "count_total", value: "#1 count" }
    ];

    const rows = parsedRankings.map((player, index) => ({
        key: player.user_id,
        values: {
            ...player,
            name: <StyledName user_id={player.user_id} name={player.username} donator={player.is_donator} country={country.code === "all" ? player.country : undefined} />,
            average_score_formatted: formatNumber(player.average_score),
            country: player.country?.toUpperCase() || "",
            rank_average_score: player.player_rank,
            rank_average_score_combined: player.player_rank
        }
    }));

    const update = (newSearchValue: string | null) => {
        if (newSearchValue !== null) setSearchValue(newSearchValue);
    };

    return (
        <>
            <RankingsFilters update={update} showSearchBar searchDelay={500} />
            <RankingsTable headers={headers} rows={rows} mainField="average_score_formatted" loading={loading} />
        </>
    );
}

export default withStyles(styles)(AverageScoreRankings)