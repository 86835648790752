import React, { useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import CountryBasedContainer from '../CountryBasedContainer/CountryBasedContainer';
import SettingsComponent from '../../../modules/Settings/Settings';
import HelpComponent from '../../../modules/Help/Help';
import DonateComponent from '../../../modules/Donate/Donate';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../store';
import Confetti from '../Confetti/Confetti';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default
    }
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const BaseContainer: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const { settings } = useSelector((state: IApplicationState) => state);
    const location = useLocation();

    useEffect(() => {
        const paths = location.pathname.split('/').filter(p => p.length > 0);
        if (!paths.length) return;
        if (paths[0] === "rankings" && paths.length < 4) return;

        ReactGA.pageview(location.pathname);
    }, [location]);

    return (
        <div className={classes.root}>
            <Confetti />
            <Switch>
                <Route path={['/rankings', '/player']} component={CountryBasedContainer} />
                <Route path={'/settings'} component={SettingsComponent} />
                <Route path={'/help'} component={HelpComponent} />
                <Route path={'/donate'} component={DonateComponent} />
                <Route><Redirect to={`/rankings/${settings.defaultCountry}`} /></Route>
            </Switch>
        </div>
    );
}

export default withStyles(styles)(BaseContainer)