import React, { useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import { useFetch } from '../../../../common/hooks/useAPICall';
import { usePlayerDataRounder } from '../../../../common/hooks/usePlayerDataRounder';
import RankingsTable from '../RankingsTable/RankingsTable';
import { convertGamemodeToString, convertModsToModsString } from '../../../../common/helpers/generic-helpers';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';
import RankingsFilters from '../RankingsFilters/RankingsFilters';
import { Mods } from '../../../../common/interfaces/Mods';
import StyledName from '../../../../common/components/StyledName/StyledName';
import { RankingsPlayer, RankingsPlayerCount } from '../../../../common/interfaces/RankingsPlayer';
import CountWithDifference from '../../../../common/components/CountWithDifference';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const CountRankings: React.FC<PropsType> = (props) => {
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [selectedMods, setSelectedMods] = useState('');
    const [searchValue, setSearchValue] = useState<string>('');
    const { response: rankings, loading } = useFetch<RankingsPlayerCount[]>(`rankings/${country.code}/count/?mode=${gamemodeString}&mods=${selectedMods}&search=${searchValue}`);
    const parsedRankings = usePlayerDataRounder(rankings);

    if (loading && !rankings)
        return <LoadingIcon />;

    const headers = [
        { key: country.code === "all" ? "rank_count_combined" : "rank_count", value: 'Rank' },
        { key: 'name', value: 'Name' },
        { key: 'count', value: '#1 count' },
        { key: 'weighted_pp', value: 'Weighted pp' },
        { key: 'average_pp', value: 'Average pp' },
        { key: 'average_sr', value: 'Average SR' }
    ];

    const getStyledName = (player: RankingsPlayer) => <StyledName user_id={player.user_id} name={player.username} donator={player.is_donator} country={country.code === "all" ? player.country : undefined} />;

    const rows = parsedRankings.map((player, index) => ({
        key: player.user_id,
        values: {
            ...player,
            name: getStyledName(player),
            count: player.mods_count_total ?? <CountWithDifference count={player.count_total} difference={player.count_difference} />,
            rank_count: player.player_rank,
            rank_count_combined: player.player_rank,
            country: player.country?.toUpperCase()
        }
    }));

    const update = (newSearchValue: string | null, newMods: Mods | null) => {
        if (newSearchValue !== null) setSearchValue(newSearchValue);
        if (newMods) setSelectedMods(convertModsToModsString(newMods));
    };

    return (
        <>
            <RankingsFilters showModsSelector showSearchBar update={update} searchDelay={500} />
            <RankingsTable headers={headers} rows={rows} mainField='count' loading={loading} />
        </>
    );
}

export default withStyles(styles)(CountRankings)