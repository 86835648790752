import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import BeatmapPageLink from './BeatmapPageLink';
import OsuDirectLink from './OsuDirectLink';

interface IProps {
    beatmapPage?: boolean,
    osuDirect?: boolean
    mapId: number;
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        // minWidth: 100
    }
});

type ClassKey = 'root';
type PropsType = IProps & WithStyles<ClassKey>;

const TableLinks: React.FC<PropsType> = (props) => {
    const { classes, beatmapPage, osuDirect, mapId } = props;;

    return (
        <div className={classes.root}>
            {beatmapPage && <BeatmapPageLink mapId={mapId} />}
            {osuDirect && <OsuDirectLink mapId={mapId} />}
        </div>
    );
};

export default withStyles(styles)(TableLinks);