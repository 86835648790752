import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';

interface IProps {
    country: string;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        width: "23pt",
        height: "18pt",
        marginRight: theme.spacing(1)
    }
});

type ClassKey = 'root';
type PropsType = IProps & WithStyles<ClassKey>

const CountryFlagIcon: React.FC<PropsType> = (props) => {
    const { classes, country } = props;
    let svg;
    try {
        svg = require(`./flags/${country.toLowerCase()}.svg`);
    }
    catch (error) {
        svg = "";
    }
    return (
        <img className={classes.root} src={svg} alt={country} />
    );
};

export default withStyles(styles)(CountryFlagIcon)