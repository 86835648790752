import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { CountryContext, PlayerContext } from '../../../../../common/context';
import AreaChart from '../../../../../common/components/Charts/AreaChart/AreaChart';
import { useFetch } from '../../../../../common/hooks/useAPICall';
import { SqlPlayerHistory } from '../../../../../common/interfaces/DetailedPlayer';
import LoadingIcon from '../../../../../common/components/LoadingIcon/LoadingIcon';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    historyChart: {
        width: "97%",
        height: "85%",
        marginLeft: "1.5%",
        marginTop: "1.5%"
    }
})

type ClassKey = 'root' | "historyChart";
type PropsType = IProps & WithStyles<ClassKey>

const HistoryAreaChart: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const player = useContext(PlayerContext);
    const country = useContext(CountryContext);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { loading, response } = useFetch<SqlPlayerHistory[]>(`player/${country.code}/${player.user_id}/history`);

    if (loading) return <LoadingIcon />;
    if (!response) return null;

    response.sort((a, b) => a.history_date < b.history_date ? -1 : 1);

    const chartLabels: string[] = response.map(h => {
        const [year, month, day] = h.history_date.split('-');
        return `${year}-${month.length === 1 ? `0${month}` : month}-${day.length === 1 ? `0${day}` : day}`;
    });

    const filteredLabels = isSmallScreen ? chartLabels.slice(chartLabels.length - 25, chartLabels.length) : chartLabels;
    const data = response.map(r => r.count_total);
    const filteredData = isSmallScreen ? data.slice(data.length - 25, data.length) : data;

    const chartData = {
        label: "#1 count",
        data: filteredData,
        backgroundColor: theme.palette.primary.light,
        pointRadius: chartLabels.length > 50 ? 0 : 3,
    };

    return (
        <div className={classes.historyChart}>
            <AreaChart labels={filteredLabels} datasets={[chartData]} />
        </div>
    );
}

export default withStyles(styles)(HistoryAreaChart)