import { IAppThunkAction } from "..";
import config from "../../common/config";
import { Country } from "../../common/interfaces/Country";
import { SnipeServer } from "../../common/interfaces/SnipeServer";
import { IGetCountriesAction } from "./actions"

export type KnownAction = IGetCountriesAction;

export const countryActions = {
    getCountries: (): IAppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            const countriesPromise = fetch(`${config.api}/country/all?only_with_data=true`, {
                method: 'GET',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                })
            });

            const snipeServersPromise = fetch(`${config.api}/server/invite/all`, {
                method: 'GET',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                })
            });

            const responses = await Promise.all([countriesPromise, snipeServersPromise]);
            const [countries, snipeServers] = await Promise.all(responses.map(r => r.json())) as [Country[], SnipeServer[]];

            snipeServers.forEach(server => {
                const country = countries.find(c => c.code === server.country);
                if (country) country.invite_id = server.invite_id;
            });

            if (countries)
                dispatch({ type: "GET_COUNTRIES", payload: countries });
        }
        catch (error) {
            console.error(error);
        };
    }
};