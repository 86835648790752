import { Action, Reducer } from "redux";
import { KnownAction } from ".";
import { Country } from "../../common/interfaces/Country";

export const countriesReducer: Reducer<Country[]> = (state: Country[] = [], incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "GET_COUNTRIES":
            return [ ...action.payload ];
        default:
            return state;
    };
};