import React, { useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Country } from '../../interfaces/Country';
import config from '../../config';
import { CountryContext, GamemodeContext } from '../../context';
import PlayerDetails from '../../../modules/PlayerDetails/PlayerDetails';
import Rankings from '../../../modules/Rankings/Rankings';
import { Gamemode } from '../../interfaces/Gamemode';
import { convertStringToGamemode } from '../../helpers/generic-helpers';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const CountryBasedContainer: React.FC<PropsType> = (props) => {
    const [country, setCountry] = useState({} as Country);
    const [gamemode, setGamemode] = useState(Gamemode.Osu);

    const location = useLocation();

    useEffect(() => {
        const pathsToCheck = ['/rankings/', '/player/'];
        let code = '';
        for (let i = 0; i < pathsToCheck.length; i++) {
            const path = pathsToCheck[i];
            if (location.pathname.includes(path)) {
                code = location.pathname.split('/')[2];
                setGamemode(convertStringToGamemode(location.pathname.split('/')[3]));
                break;
            }
        }

        if (code !== '') {
            fetch(`${config.api}/country/code/${code}`)
                .then(res => {
                    res.json().then(r => {
                        setCountry(r);
                    });
                });
        }
    }, [location.pathname]);

    if (!country.code)
        return (<></>);

    return (
        <CountryContext.Provider value={country}>
            <GamemodeContext.Provider value={gamemode}>
                <Switch>
                    <Route path={'/rankings'} component={Rankings} />
                    <Route path={'/player'} component={PlayerDetails} />
                </Switch>
            </GamemodeContext.Provider>
        </CountryContext.Provider>
    )
}

export default withStyles(styles)(CountryBasedContainer)