import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

interface IProps {
    count: number,
    difference?: number | null
};

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;
const styles = (theme: Theme) => createStyles<ClassKey, Record<string, any>>({
    root: {}
});

const CountWithDifference: React.FC<PropsType> = (props) => {
    const { count, difference } = props;

    if (!difference && difference !== 0) return <>{count}</>;
    const color = difference < 0 ? "#d62929" : (difference === 0 ? "#f0f08d" : "#23fa1b");

    return (
        <>
            {count}
            <span style={{ color, marginLeft: 12 }}>{difference > 0 ? "+" : ""}{difference === 0 ? "-" : difference}</span>
        </>
    );
};

export default withStyles(styles)(CountWithDifference);