import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { SniperSummary, SqlSnipesRankings } from '../../../../../common/interfaces/SnipeTarget';
import BaseTable from '../../../../../common/components/BaseTable/BaseTable';
import { useHistory } from 'react-router-dom';
import { CountryContext } from '../../../../../common/context';

interface IProps {
    targetId: number,
    targetSnipes: SqlSnipesRankings[],
    summaries: SniperSummary[]
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const SnipesRankingsTable: React.FC<PropsType> = (props) => {
    const { targetId, targetSnipes, summaries } = props;
    const country = useContext(CountryContext);

    const totalSnipes = targetSnipes.reduce<number>((total, current) => total + current.snipe_count, 0);

    const history = useHistory();

    const headers = [
        { key: "player_rank", value: "Rank" },
        { key: "username", value: "Name" },
        { key: "snipe_count", value: "Snipes" }
    ];

    if (targetId !== -1)
        headers.push(...[
            { key: "target_activity", value: "Target activity" },
            { key: "total_snipes", value: "Total snipes" },
            { key: "total_share", value: "Total share" }
        ]);

    const snipes = targetId === -1
        ? targetSnipes.reduce<SqlSnipesRankings[]>((total, current) => {
            const existing = total.find(t => t.user_id === current.user_id);
            if (existing) existing.snipe_count += current.snipe_count;
            else total.push({ ...current });
            return total;
        }, []).sort((a, b) => b.snipe_count - a.snipe_count)
        : targetSnipes;

    const rows = snipes.map((sniper, index) => {
        const summary = summaries.find(s => s.user_id === sniper.user_id);
        return {
            key: sniper.user_id,
            values: {
                ...sniper,
                player_rank: targetId === -1 ? index + 1 : sniper.player_rank,
                target_activity: `${Math.round(sniper.snipe_count / totalSnipes * 10000) / 100}%`,
                total_snipes: summary?.total_snipes || "?",
                total_share: summary?.total_snipes ? `${Math.round(sniper.snipe_count / summary.total_snipes * 10000) / 100}%` : "?"
            }
        };
    });

    const onRowClick = (sniper: any) => {
        history.push(`/player/${country.code}/osu/${sniper.user_id}`);
    };

    return <BaseTable headers={headers} rows={rows} handleClick={onRowClick} />;
}

export default withStyles(styles)(SnipesRankingsTable)