import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import LinkTabs from '../../../../common/components/LinkTabs/LinkTabs';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import LinkSelectBox from '../../../../common/components/LinkSelectBox/LinkSelectBox';
import MobileOnly from '../../../../common/components/MobileHelpers/MobileOnly';
import NoMobile from '../../../../common/components/MobileHelpers/NoMobile';
import { convertGamemodeToString } from '../../../../common/helpers/generic-helpers';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    linkSelectBox: {
        width: '90%'
    }
})

type ClassKey = 'root' | 'linkSelectBox'
type PropsType = IProps & WithStyles<ClassKey>

const RankingsNavigation: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const navigationItems = [
        { id: 'weighted-pp', label: 'Weighted pp' },
        { id: 'count', label: 'Count' },
        { id: 'average-pp', label: 'Average pp' },
        { id: 'average-sr', label: 'Average star rating' },
        { id: 'average-acc', label: 'Average accuracy' },
        { id: 'average-score', label: 'Average score' },
        { id: "total-score", label: "Total score" }
    ];

    if (country.code !== "all")
        navigationItems.push(...[
            { id: 'oldest', label: 'Oldest #1' },
            { id: 'custom', label: 'Custom' },
            { id: 'unplayed', label: 'Unplayed' }
        ]);

    if (country.snipes_supported)
        navigationItems.push({ id: 'snipes', label: 'Snipes' });

    return (
        <>
            <NoMobile>
                <LinkTabs parentRoute={`/rankings/${country.code}/${gamemodeString}`} tabs={navigationItems} defaultValue={window.location.href.split('/').pop() || 'weighted-pp'} />
            </NoMobile>
            <MobileOnly>
                <LinkSelectBox formControlStyles={classes.linkSelectBox} parentRoute={`/rankings/${country.code}/${gamemodeString}`} menuItems={navigationItems} defaultValue={window.location.href.split('/').pop() || 'weighted-pp'} />
            </MobileOnly>
        </>
    )
}

export default withStyles(styles)(RankingsNavigation)