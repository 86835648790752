import React, { useContext, useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Button } from '@material-ui/core';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import { useFetch } from '../../../../common/hooks/useAPICall';
import { RankingsPlayerCustom } from '../../../../common/interfaces/RankingsPlayer';
import RankingsTable from '../RankingsTable/RankingsTable';
import BasicSelectBox from '../../../../common/components/SelectBoxes/BasicSelectBox';
import ModsSelector from '../../../../common/components/ModsSelector/ModsSelector';
import { Mods } from '../../../../common/interfaces/Mods';
import { convertGamemodeToString } from '../../../../common/helpers/generic-helpers';
import TableLinks from '../../../../common/components/OsuDirectLink/TableLinks';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    selectBox: {
        width: '10%',
        marginLeft: '10px'
    },
    filters: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '10px'
    },
    confirmButton: {
        marginLeft: '10px'
    }
});

type ClassKey = 'root' | 'selectBox' | 'filters' | 'confirmButton';
type PropsType = IProps & WithStyles<ClassKey>

const CustomRankings: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [mods, setMods] = useState('');
    const [sortingField, setSortingField] = useState('pp');
    const [currentSortingField, setCurrentSortingField] = useState('pp');
    const [sortingOrder, setSortingOrder] = useState<"ASC" | "DESC">("DESC");
    const [queryParams, setQueryParams] = useState({ mods, sortingOrder, sortingField });

    const { response: rankings, loading } = useFetch<RankingsPlayerCustom[]>(`rankings/${country.code}/custom?mods=${queryParams.mods}&sortingField=${queryParams.sortingField}&sortingOrder=${queryParams.sortingOrder}&mode=${gamemodeString}`);
    
    useEffect(() => {
        setCurrentSortingField(sortingField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rankings]);

    if (loading && !rankings.length)
        return <LoadingIcon />;

    const sortingOptions = [
        { id: "pp", label: "Performance points" },
        { id: "sr", label: "Star rating" }
    ];

    const sortingOrderOptions = [
        { id: "ASC", label: "Ascending" },
        { id: "DESC", label: "Descending" }
    ];

    const headers = [
        { key: "rank_custom", value: "Rank" },
        { key: "username", value: "Player" },
        { key: currentSortingField, value: currentSortingField.toUpperCase() },
        { key: "map_name", value: "Map" },
        { key: "mods", value: "Mods" },
        { key: "accuracy", value: "Accuracy" },
        { key: "links", value: "" }
    ];

    const rows = rankings.map((row, index) => ({
        key: index + 1,
        values: {
            ...row,
            accuracy: Math.round(row.accuracy * 10000) / 100,
            pp: Math.round(row.pp),
            sr: Math.round(row.sr * 100) / 100,
            rank_custom: index + 1,
            map_name: `${row.artist} - ${row.title} [${row.diff_name}]`,
            mods: row.mods === "nomod" ? "" : row.mods,
            links: <TableLinks beatmapPage osuDirect mapId={row.map_id} />
        }
    }));

    const modsHandler = (mods: Mods) => {
        const selectedMods = Object.keys(mods).reduce((total, current) => {
            if (mods[current as keyof Mods])
                return total + current;
            return total;
        }, "");

        setMods(selectedMods);
    };

    const sortingFieldHandler = (newSortingField: string) => {
        setSortingField(newSortingField);
    };

    const sortingOrderHandler = (newSortingOrder: string) => {
        setSortingOrder(newSortingOrder as "ASC" | "DESC");
    };

    const confirmChanges = () => {
        setQueryParams({ mods, sortingField, sortingOrder });
    };

    return (
        <>
            <div className={classes.filters}>
                <ModsSelector update={modsHandler} />
                <BasicSelectBox items={sortingOptions} label="Sorting field" defaultValue="pp" handleValueChange={sortingFieldHandler} selectBoxStyling={classes.selectBox} />
                <BasicSelectBox items={sortingOrderOptions} label="Sorting order" defaultValue="DESC" handleValueChange={sortingOrderHandler} selectBoxStyling={classes.selectBox} />
                <Button className={classes.confirmButton} color="primary" onClick={confirmChanges}>Confirm</Button>
            </div>
            <RankingsTable headers={headers} rows={rows} mainField={sortingField} />
        </>
    );
};

export default withStyles(styles)(CustomRankings)