import React, { useContext, useEffect, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Button } from '@material-ui/core';
import { PlayerContext } from '../../../../../common/context';
import PieChart from '../../../../../common/components/Charts/PieChart/PieChart';
import ModsPieChartOptionsContainer, { ModsPieChartOptions } from './ModsPieChartOptionsContainer';
import { combineMods, ignoreMods } from '../../../player-details-helper';
import FavouriteMods from './FavouriteMods';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../../store';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    optionsContainer: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },
    chartsContainer: {
        [theme.breakpoints.up('sm')]: {
            display: "flex",
            justifyContent: "space-between",
        },
        height: "calc(100% - 82.5px)"
    },
    modsChart: {
        [theme.breakpoints.up('sm')]: {
            width: "85%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        height: "350px",
    },
    favouriteModsContainer: {
        [theme.breakpoints.up('sm')]: {
            width: "50%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        height: "100%",
        marginTop: -50
    },
    showAllModsButton: {
        height: 30
    }
});

type ClassKey = 'root' | "optionsContainer" | "chartsContainer" | "modsChart" | "favouriteModsContainer" | "showAllModsButton";
type PropsType = IProps & WithStyles<ClassKey>

const ModsPieChart: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const player = useContext(PlayerContext);
    const { useOldColors } = useSelector((state: IApplicationState) => state.settings);
    const [showOtherSliceDetails, setShowOtherSliceDetails] = useState<boolean>(false);
    const [modsData, setModsData] = useState<{ name: string, value: number }[]>([]);

    const newOptions: ModsPieChartOptions = {
        combineNightcore: false,
        ignoreSuddenDeathAndPerfect: false,
        ignoreNoFail: false,
        ignoreSpunOut: false,
        showLabels: true
    };
    const [options, setOptions] = useState<ModsPieChartOptions>(newOptions);

    useEffect(() => {
        if (player && options) {
            const modKeys = Object.keys(player.mods_count);
            let data: { name: string, value: number }[] = modKeys.map(mods => ({ name: mods === "nomod" ? "NM" : mods, value: player.mods_count[mods] }));
    
            if (options.combineNightcore)
                combineMods(data, "NC", "DT");
    
            if (options.ignoreSuddenDeathAndPerfect)
                ignoreMods(data, ["SD", "PF"]);
    
            if (options.ignoreNoFail)
                ignoreMods(data, ["NF"]);
    
            if (options.ignoreSpunOut)
                ignoreMods(data, ["SO"]);
    
            const lowParts = data.filter(pair => pair.value / player.count_total < 0.01);
            if (!showOtherSliceDetails) {
                const totalLowParts = lowParts.reduce((total, current) => total + current.value, 0);
                data = data.filter(pair => pair.value / player.count_total >= 0.01);
                data.push({ name: "Other", value: totalLowParts });
            }
            else
                data = lowParts;
    
            setModsData(data.sort((a, b) => b.value - a.value));
        }
    }, [showOtherSliceDetails, options, player]);

    if (player.count_total === 0)
        return <>No #1s</>;

    const getPieColors = (dataLength: number): string[] => {
        if (useOldColors) return ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];
        const basicPieColors = ["#F47A1F", "#FDBB2F", "#377B2B", "#7AC142", "#007CC3", "#00529B"];
        if (dataLength > 6)
            basicPieColors.splice(0, 0, "#ba2f2f");
        if (dataLength > 7)
            basicPieColors.push("#9e0eca");
        if (dataLength > 8)
            basicPieColors.push("#f8348e");
        if (dataLength > 9)
            basicPieColors.splice(5, 0, "#10b4c3");
        return basicPieColors;
    };

    const modsCombinationsLabels = modsData.map(m => m.name === "nomod" ? "NM" : m.name);
    const labels = [...modsCombinationsLabels];

    const modsCombinationsData = modsData.map(m => m.value);

    const pieColors = getPieColors(modsCombinationsData.length);
    while (pieColors.length < labels.length) {
        pieColors.push(...pieColors);
    }

    const datasets = [
        {
            data: modsCombinationsData,
            label: "Used mod combinations",
            labels: modsCombinationsLabels,
            backgroundColor: pieColors
        },
    ];

    const modsPieChartOptionsChanged = (options: ModsPieChartOptions) => {
        setOptions(options);
    };

    const onClick = (event: any, chart: Chart) => {
        const activePoints: any[] = chart.getElementsAtEvent(event);
        if (!activePoints.length) return;
        const chartData = activePoints[0]._chart.config.data;
        const index = activePoints[0]._index;
        const label = chartData.labels[index];
        if (label === "Other")
            setShowOtherSliceDetails(true);
    };

    return (
        <>
            <div className={classes.optionsContainer}>
                <ModsPieChartOptionsContainer optionsChanged={modsPieChartOptionsChanged} />
                {showOtherSliceDetails && <Button className={classes.showAllModsButton} color="primary" variant="outlined" onClick={() => setShowOtherSliceDetails(false)}>Show all mods</Button>}
            </div>
            <div className={classes.chartsContainer}>
                <div className={classes.modsChart}>
                    <PieChart labels={labels} datasets={datasets} showLabels={options.showLabels} onClick={onClick} />
                </div>
                <div className={classes.favouriteModsContainer}>
                    <FavouriteMods />
                </div>
            </div>
        </>
    );
}

export default withStyles(styles)(ModsPieChart)