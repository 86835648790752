import React, { useState, useEffect, useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme, IconButton, Drawer, List, ListItem, ListItemText, Collapse, ListItemIcon, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PublicIcon from '@material-ui/icons/Public';
import SettingsIcon from '@material-ui/icons/Settings';
import FlagIcon from '@material-ui/icons/Flag';
import HelpIcon from '@material-ui/icons/Help';
import EuroIcon from '@material-ui/icons/Euro';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import bxYen from '@iconify-icons/bx/bx-yen';
import bxPound from '@iconify-icons/bx/bx-pound';
import HistoryIcon from '@material-ui/icons/History';
import discordIcon from '@iconify/icons-mdi/discord';
import targetIcon from '@iconify/icons-mdi/target';
import { Country } from '../../interfaces/Country';
import { useHistory } from 'react-router-dom';
import { GamemodeContext } from '../../context';
import { convertGamemodeToString } from '../../helpers/generic-helpers';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Icon from '@iconify/react';
import CountryFlagIcon from '../CountryFlagIcon/CountryFlagIcon';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../store';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    list: {
        width: 275,
        overflowX: "hidden"
    },
    nestedItem: {
        paddingLeft: theme.spacing(4)
    },
    flag: {
        width: "23pt",
        height: "15pt",
    },
    discordIcon: {
        fontSize: '1.6rem'
    }
});

type ClassKey = 'root' | 'list' | 'nestedItem' | 'flag' | 'discordIcon';
type PropsType = IProps & WithStyles<ClassKey>

const SideBar: React.FC<PropsType> = (props) => {
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const { countries, settings } = useSelector((state: IApplicationState) => state);
    const [parsedCountries, setParsedCountries] = useState<Country[]>([]);

    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const history = useHistory();

    const [countryRankingsOpen, setCountryRankingsOpen] = useState(false);
    const [snipeServersOpen, setSnipeServersOpen] = useState(false);
    const [donationIcon, setDonationIcon] = useState<any>();

    const { classes } = props;

    const defaultCountry = countries?.find(c => c.code === settings.defaultCountry);

    useEffect(() => {
        if (countries.length) {
            const global = countries.find(c => c.code === 'global');
            if (global) global.country_name = 'Global';
            const updatedCountries = countries
                .map(country => ({
                    ...country,
                    name: country.country_name.includes('the ') ? country.country_name.split('the ')[1] : country.country_name
                }))
                .sort((a, b) => a.name > b.name ? 1 : -1)
                .filter(country => country.code !== "global");
            setParsedCountries(updatedCountries);
        }
    }, [countries]);

    useEffect(() => {
        if (sideBarOpen) {
            const seed = Math.floor(Math.random() * 4);
            switch (seed) {
                case 0: setDonationIcon(<EuroIcon />); break;
                case 1: setDonationIcon(<AttachMoneyIcon />); break;
                case 2: setDonationIcon(<Icon className={classes.discordIcon} icon={bxPound} />); break;
                case 3: setDonationIcon(<Icon className={classes.discordIcon} icon={bxYen} />); break;
            };
        }
    }, [sideBarOpen, classes.discordIcon]);

    const toggleSideBar = () => {
        setSideBarOpen(!sideBarOpen);
    }

    const collapseCountryRankings = (): void => {
        setCountryRankingsOpen(!countryRankingsOpen);
    };

    const collapseSnipeServers = (): void => {
        setSnipeServersOpen(!snipeServersOpen);
    };

    const navigateToUrl = (url: string): void => {
        history.push(url);
        setSideBarOpen(false);
    };

    const navigateToCountryRankings = (countryCode: string): void => {
        navigateToUrl(`/rankings/${countryCode}/${gamemodeString}`);
    };

    const navigateToGlobalRankings = (): void => {
        navigateToUrl('/rankings/global');
    };

    const navigateToSnipeServer = (inviteId: string | null): void => {
        if (!inviteId) return;
        window.open(`https://discord.gg/${inviteId}`);
        setSideBarOpen(false);
    };

    const navigateToSettings = (): void => {
        navigateToUrl('/settings');
    };

    const navigateToHelp = (): void => {
        navigateToUrl('/help');
    };

    const navigateToDonate = (): void => {
        // navigateToUrl('/donate');
        window.open("https://www.paypal.com/paypalme/mrhelix");
        setSideBarOpen(false);
    };

    const openDiscordInvite = (): void => {
        window.open("https://discordapp.com/invite/ExMDcT5");
        setSideBarOpen(false);
    };

    const navigateToOldSite = (): void => {
        window.open("https://old.huismetbenen.nl");
        setSideBarOpen(false);
    };

    return (
        <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSideBar}>
                <MenuIcon />
            </IconButton>
            <Drawer open={sideBarOpen} onClose={toggleSideBar}>
                <div className={classes.list}>
                    <List>
                        {defaultCountry && (
                            <ListItem button onClick={() => navigateToCountryRankings(defaultCountry.code)}>
                                <ListItemIcon><CountryFlagIcon country={defaultCountry.country_code} /></ListItemIcon>
                                <ListItemText primary="Default country" />
                            </ListItem>
                        )}
                        <ListItem button onClick={collapseCountryRankings}>
                            <ListItemIcon><FlagIcon /></ListItemIcon>
                            <ListItemText primary="Country rankings" />
                            {countryRankingsOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={countryRankingsOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nestedItem} key={"all"} onClick={() => navigateToCountryRankings("all")}>
                                    <ListItemIcon><PublicIcon /></ListItemIcon>
                                    <ListItemText primary="Countries combined" />
                                </ListItem>
                                {parsedCountries.map(item => (
                                    <ListItem button className={classes.nestedItem} key={item.code} onClick={() => navigateToCountryRankings(item.code)}>
                                        <ListItemIcon>
                                            <CountryFlagIcon country={item.country_code} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.country_name} />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                        <ListItem button onClick={navigateToGlobalRankings}>
                            <ListItemIcon><PublicIcon /></ListItemIcon>
                            <ListItemText primary="Global rankings" />
                        </ListItem>
                        <ListItem button onClick={collapseSnipeServers}>
                            <ListItemIcon><Icon className={classes.discordIcon} icon={targetIcon} /></ListItemIcon>
                            <ListItemText primary="Snipe servers" />
                            {snipeServersOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={snipeServersOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {parsedCountries.filter(item => item.invite_id).map(item => (
                                    <ListItem button className={classes.nestedItem} key={item.code} onClick={() => navigateToSnipeServer(item.invite_id)}>
                                        <ListItemIcon>
                                            <CountryFlagIcon country={item.country_code} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.country_name} />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                        <Divider />
                        <ListItem button onClick={navigateToSettings}>
                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                            <ListItemText primary="Settings" />
                        </ListItem>
                        <ListItem button onClick={navigateToHelp}>
                            <ListItemIcon><HelpIcon /></ListItemIcon>
                            <ListItemText primary="Help" />
                        </ListItem>
                        <ListItem button onClick={navigateToDonate}>
                            <ListItemIcon>{donationIcon}</ListItemIcon>
                            <ListItemText primary="Donate" />
                        </ListItem>
                        <ListItem button onClick={openDiscordInvite}>
                            <ListItemIcon><Icon className={classes.discordIcon} icon={discordIcon} /></ListItemIcon>
                            <ListItemText primary="Discord server" />
                        </ListItem>
                        <ListItem button onClick={navigateToOldSite}>
                            <ListItemIcon><HistoryIcon /></ListItemIcon>
                            <ListItemText primary="Old website" />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </>
    )
}

export default withStyles(styles)(SideBar)