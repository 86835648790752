import React, { useState, ChangeEvent } from 'react';
import { withStyles, WithStyles, createStyles, Theme, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface IProps {
    parentRoute: string;
    menuItems: { id: string, label: string }[];
    defaultValue?: string;
    formControlStyles: any;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    selectBox: {
        textAlign: 'left'
    }
})

type ClassKey = 'root' | 'selectBox'
type PropsType = IProps & WithStyles<ClassKey>

const LinkSelectBox: React.FC<PropsType> = (props) => {
    const { classes, parentRoute, menuItems, defaultValue, formControlStyles } = props;
    const [rankingsType, setRankingsType] = useState(defaultValue || menuItems[0].id);
    const history = useHistory();

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const newPath = event.target.value as string;
        setRankingsType(newPath);
        const link = `${parentRoute}/${newPath}`;
        history.push(link);
    };

    return (
        <FormControl className={formControlStyles}>
            <InputLabel>Rankings type</InputLabel>
            <Select className={classes.selectBox} value={rankingsType} onChange={handleChange}>
                {menuItems.map(menuItem => (
                    <MenuItem key={menuItem.id} value={menuItem.id}>{menuItem.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default withStyles(styles)(LinkSelectBox)