import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Button } from '@material-ui/core';
import NoMobile from '../MobileHelpers/NoMobile';
import MobileOnly from '../MobileHelpers/MobileOnly';
import ModsSelectorDrawer from './ModsSelectorDrawer';
import ModsSelectorButtons from './ModsSelectorButtons';
import { Mods } from '../../interfaces/Mods';

interface IProps {
    update: (mods: Mods) => void;
    shouldApply?: boolean;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const ModsSelector: React.FC<PropsType> = (props) => {
    const { update, shouldApply } = props;
    const [selectedMods, setSelectedMods] = useState<Mods>({ nomod: false, EZ: false, NF: false, HT: false, HD: false, HR: false, DT: false, NC: false, FL: false });

    const handleChange = (newSelection: Mods, mostRecentMod: keyof Mods, value: boolean) => {
        if (!!value) {
            if (mostRecentMod !== 'nomod')
                newSelection.nomod = false;
            switch (mostRecentMod) {
                case 'DT':
                    newSelection.NC = false;
                    newSelection.HT = false;
                    break;
                case 'NC':
                    newSelection.DT = false;
                    newSelection.HT = false;
                    break;
                case 'HT':
                    newSelection.DT = false;
                    newSelection.NC = false;
                    break;
                case 'EZ':
                    newSelection.HR = false;
                    break;
                case 'HR':
                    newSelection.EZ = false;
                    break;
                case 'nomod':
                    Object.keys(newSelection).forEach(key => newSelection[key as keyof Mods] = false);
                    newSelection.nomod = true;
                    break;
            }
        }
        setSelectedMods(newSelection);
        if (!shouldApply) update(newSelection);
    };

    const apply = (): void => {
        update(selectedMods);
    };

    return (
        <div>
            <MobileOnly>
                <ModsSelectorDrawer selectedMods={selectedMods} update={handleChange} />
            </MobileOnly>
            <NoMobile>
                <ModsSelectorButtons selectedMods={selectedMods} update={handleChange} />
            </NoMobile>
            {shouldApply && <Button color="primary" onClick={apply}>Apply</Button>}
        </div>
    );
}

export default withStyles(styles)(ModsSelector)