import React, { useEffect, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../../store';
import { settingsActions } from '../../../store/settings';
import BaseContainer from '../BaseContainer/BaseContainer';
import { countryActions } from '../../../store/countries';
import config from '../../config';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const MainContainer: React.FC<PropsType> = (props) => {
    const { settings } = useSelector((state: IApplicationState) => state);
    const dispatch = useDispatch();
    const [theme, setTheme] = useState<Theme>();

    useEffect(() => {
        dispatch(settingsActions.getSettings());
        dispatch(countryActions.getCountries());
    }, [dispatch]);

    useEffect(() => {
        let extraOptions: any = { primary: { main: null } };
        if (config.env === "dev")
            extraOptions.primary.main = "#008000"; // green
        else if (config.env === "staging")
            extraOptions.primary.main = "#e8371c"; // red
        else if (settings.theme === "dark")
            extraOptions.primary.main = "#1976d2";

        if (!extraOptions.primary.main)
            extraOptions = {};
    
        setTheme(createMuiTheme({ palette: { type: settings.theme, ...extraOptions }, }));
    }, [settings.theme]);

    if (!settings?.defaultCountry || !theme) return null;

    return (
        <ThemeProvider theme={theme}>
            <BaseContainer />
        </ThemeProvider>
    );
}


export default withStyles(styles)(MainContainer)