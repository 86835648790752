import React, { ChangeEvent } from 'react';
import { withStyles, WithStyles, createStyles, Theme, List, ListItem, ListItemText, FormControl, InputLabel, Select, MenuItem, Switch } from '@material-ui/core';
import ToolBar from '../../common/components/ToolBar/ToolBar';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '../../store';
import { Settings } from '../../common/interfaces/Settings';
import { settingsActions } from '../../store/settings';
import LoadingIcon from '../../common/components/LoadingIcon/LoadingIcon';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    toolbar: {
        height: 64
    },
    settingsList: {
        [theme.breakpoints.up('md')]: {
            width: "50%",
        },
        color: theme.palette.text.primary
    },
    formControl: {
        [theme.breakpoints.up('md')]: {
            width: 300
        },
        [theme.breakpoints.down('sm')]: {
            width: "50%"
        }
    }
});

type ClassKey = 'root' | 'toolbar' | 'settingsList' | 'formControl';
type PropsType = IProps & WithStyles<ClassKey>

const SettingsComponent: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const { settings, countries } = useSelector((state: IApplicationState) => state);
    const dispatch = useDispatch();

    const tabs = [
        { code: "weighted-pp", name: "Weighted pp" },
        { code: "count", name: "Count" },
        { code: "average-pp", name: "Average pp" },
        { code: "average-sr", name: "Average star rating" },
        { code: "average-acc", name: "Average accuracy" },
        { code: "average-score", name: "Average score" },
        { code: "total-score", name: "Total score" },
        { code: "oldest", name: "Oldest #1" }
    ];

    const themes = [{ code: "light", name: "Light" }, { code: "dark", name: "Dark" }];
    const dateFormats = [{ code: "ymd", name: "YYYY-MM-DD" }, { code: "dmy", name: "DD-MM-YYYY" }, { code: "mdy", name: "MM-DD-YYYY (the wrong one)" }];

    const updateSettings = (newSettings: Settings) => {
        dispatch(settingsActions.updateSettings(newSettings));
    };

    const settingSelectChanged = (field: keyof Settings, event: ChangeEvent<{ value: unknown }>) => {
        dispatch(settingsActions.updateSettings({ ...settings, [field]: event.target.value }));
    };

    const settingToggleChanged = (field: keyof Settings) => {
        updateSettings({ ...settings, [field]: !settings[field] });
    };

    if (!settings?.defaultCountry || !countries?.length) return <LoadingIcon />;

    const parsedCountries = countries
        .filter(c => c.code !== "global")
        .map(c => c.country_name.includes("the ") ? { ...c, country_name: c.country_name.split('the ')[1] } : c)
        .sort((a, b) => a.country_name < b.country_name ? -1 : 1);

    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <ToolBar shortTitle="Settings" longTitle="Settings" />
            </div>
            <List className={classes.settingsList}>
                <ListItem>
                    <ListItemText primary="Default country" />
                    <FormControl className={classes.formControl}>
                        <InputLabel>Country</InputLabel>
                        <Select value={settings.defaultCountry} onChange={event => settingSelectChanged("defaultCountry", event)}>
                            {parsedCountries.map(country => (
                                <MenuItem key={country.code} value={country.code}>{country.country_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Default tab" />
                    <FormControl className={classes.formControl}>
                        <InputLabel>Tab</InputLabel>
                        <Select value={settings.defaultTab} onChange={event => settingSelectChanged("defaultTab", event)}>
                            {tabs.map(tab => (
                                <MenuItem key={tab.code} value={tab.code}>{tab.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Theme" />
                    <FormControl className={classes.formControl}>
                        <InputLabel>Theme</InputLabel>
                        <Select value={settings.theme} onChange={event => settingSelectChanged("theme", event)}>
                            {themes.map(theme => (
                                <MenuItem key={theme.code} value={theme.code}>{theme.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Datetime format" />
                    <FormControl className={classes.formControl}>
                        <InputLabel>Format</InputLabel>
                        <Select value={settings.dateFormat} onChange={event => settingSelectChanged("dateFormat", event)}>
                            {dateFormats.map(format => (
                                <MenuItem key={format.code} value={format.code}>{format.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Always show original difficulty attributes" />
                    <FormControl className={classes.formControl}>
                        <Switch checked={settings.forceOriginalDifficultyAttributes} onChange={() => settingToggleChanged("forceOriginalDifficultyAttributes")} color="primary" />
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Highlight global #1s in topranks" />
                    <FormControl className={classes.formControl}>
                        <Switch checked={settings.highlightGlobalTopScores} onChange={() => settingToggleChanged("highlightGlobalTopScores")} color="primary" />
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Show maps instead of players in oldest date rankings" />
                    <FormControl className={classes.formControl}>
                        <Switch checked={settings.showMapsInOldestDate} onChange={() => settingToggleChanged("showMapsInOldestDate")} color="primary" />
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Hide unsnipable maps from oldest date rankings (4 mods SS without spinners)" />
                    <FormControl className={classes.formControl}>
                        <Switch checked={settings.hideUnsnipableMaps} onChange={() => settingToggleChanged("hideUnsnipableMaps")} color="primary" title="hideUnsnipableMaps" disabled={!settings.showMapsInOldestDate} />
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Use old colors for graphs (might make the labels harder to read)" />
                    <FormControl className={classes.formControl}>
                        <Switch checked={settings.useOldColors} onChange={() => settingToggleChanged("useOldColors")} color="primary" />
                    </FormControl>
                </ListItem>
            </List>
        </div>
    );
};

export default withStyles(styles)(SettingsComponent);