import React, { MouseEvent } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Button } from '@material-ui/core';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

interface IProps {
    mapId: number;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    osuDirectButton: {
        maxHeight: 0
    }
})

type ClassKey = 'root' | 'osuDirectButton';
type PropsType = IProps & WithStyles<ClassKey>

const OsuDirectLink: React.FC<PropsType> = (props) => {
    const { classes, mapId } = props;

    const handleOsuDirectClick = (event: MouseEvent, mapId: number): void => {
        event.stopPropagation();
        window.open(`osu://b/${mapId}`, '_self');
    };

    return (
        <Button
            className={classes.osuDirectButton}
            style={{ maxWidth: 24, maxHeight: 24, minWidth: 24, minHeight: 24 }}
            size="small"
            color="primary"
            onClick={(event) => handleOsuDirectClick(event, mapId)}><SportsEsportsIcon />
        </Button>
    );
};

export default withStyles(styles)(OsuDirectLink)