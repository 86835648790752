import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Paper, Typography, Switch, FormControlLabel, Grid } from '@material-ui/core';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RecentChangesNew from './RecentChangesNew';
import RecentChangesOld from './RecentChangesOld';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        textAlign: "left"
    },
    paper: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    paperContent: {
        marginLeft: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    options: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    }
})

type ClassKey = 'root' | 'paper' | 'paperContent' | 'title' | "options";
type PropsType = IProps & WithStyles<ClassKey>

const RecentChanges: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const [dateSince, setDateSince] = useState(moment().subtract(7, "days"));
    const [dateUntil, setDateUntil] = useState(moment());
    const [includeOwnSnipes, setIncludeOwnSnipes] = useState<boolean>(false);

    const dateSinceChanged = (date: Date | null) => {
        setDateSince(moment(date?.toISOString()));
    };

    const dateUntilChanged = (date: Date | null) => {
        setDateUntil(moment(date?.toISOString()));
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.paperContent}>
                    <Typography variant="h6" className={classes.title}>Recent changes</Typography>
                    <div className={classes.options}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker label="Since" format="dd-MM-yyyy" value={dateSince} onChange={dateSinceChanged} style={{ marginRight: 8 }} />
                                    <KeyboardDatePicker label="Until" format="dd-MM-yyyy" value={dateUntil} onChange={dateUntilChanged} />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <FormControlLabel
                                    control={<Switch checked={includeOwnSnipes} onChange={() => setIncludeOwnSnipes(!includeOwnSnipes)} color="primary" />}
                                    label="Include snipes of own scores"
                                    style={{ marginLeft: 8 }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <RecentChangesNew since={dateSince} until={dateUntil} includeOwnSnipes={includeOwnSnipes} />
                    <RecentChangesOld since={dateSince} until={dateUntil} includeOwnSnipes={includeOwnSnipes} />
                </div>
            </Paper>
        </div>
    );
}

export default withStyles(styles)(RecentChanges)