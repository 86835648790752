import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Paper, Grid, List, ListItem, Collapse, Typography } from '@material-ui/core';
import StatisticsTile from './StatisticsTile';
import NoMobile from '../MobileHelpers/NoMobile';
import MobileOnly from '../MobileHelpers/MobileOnly';
import StatisticsListItem from './StatisticsListItem';
import { ExpandMore, ExpandLess } from '@material-ui/icons';


type breakpointNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface IProps {
    mainLabel?: string;
    mainComponent?: any;
    tiles: Array<{ label: string, value: string, hover?: string } | any>;
    xs: breakpointNumber;
    sm: breakpointNumber;
    md: breakpointNumber;
    lg?: breakpointNumber;
    onMainClick?: () => void;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        width: '100%',
        height: '90px',
    },
    grid: {
        width: '100%',
        height: '90px',
        display: 'flex',
        alignItems: 'center',
    },
    mainLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70%',
        fontSize: '20px',
        borderRight: '1px solid lightgrey'
    },
    mainLabelMobile: {
        height: '70%',
        fontSize: '18px'
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary
    }
})

type ClassKey = 'root' | 'grid' | 'mainLabel' | 'mainLabelMobile' | 'list';
type PropsType = IProps & WithStyles<ClassKey>

const StatisticsPanel: React.FC<PropsType> = (props) => {
    const { classes, mainLabel, mainComponent, tiles, xs, sm, md, lg, onMainClick } = props;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <NoMobile>
                <Paper className={classes.root} elevation={3}>
                    <Grid container className={classes.grid}>
                        <Grid item xs={3} md={2} className={classes.mainLabel} onClick={onMainClick}>
                            {mainComponent ?? <Typography variant="h6">{mainLabel}</Typography>}
                        </Grid>
                        {tiles.map((tile, index) => (
                            <Grid key={index} xs={xs} sm={sm} md={md} lg={lg} item>
                                {!!tile.label && <StatisticsTile label={tile.label} value={tile.value} hover={tile.hover} />}
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </NoMobile>
            <MobileOnly>
                <List component="div" className={classes.list}>
                    <ListItem button className={classes.mainLabelMobile} onClick={handleClick}>
                        {mainComponent || mainLabel} {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} unmountOnExit>
                        <List>
                            {tiles.map((tile, index) => (
                                !!tile.label && <StatisticsListItem key={index} label={tile.label} value={tile.value} addition={tile.hover} />
                            ))}
                        </List>
                    </Collapse>
                </List>
            </MobileOnly>
        </>
    )
}

export default withStyles(styles)(StatisticsPanel)