import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, Grid, Tooltip, Typography } from '@material-ui/core';

interface IProps {
    label: string;
    value: string;
    hover?: string;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        marginBottom: theme.spacing(1)
    },
    label: {
        fontSize: '12px',
        color: 'grey'
    },
    value: {
        fontSize: '20px'
    },
    hover: {
        fontSize: '14px'
    }
})

type ClassKey = 'root' | 'label' | 'value' | 'hover'
type PropsType = IProps & WithStyles<ClassKey>

const StatisticsTile: React.FC<PropsType> = (props) => {
    const { classes, hover } = props;
    
    if (hover) {
        return (
            <Tooltip title={<span className={classes.hover}>{hover}</span>}>
                <div><StatisticsTileContent {...props} /></div>
            </Tooltip>
        )
    }

    return (<StatisticsTileContent {...props} />)
}

const StatisticsTileContent: React.FC<PropsType> = (props) => {
    const { classes, label, value } = props;

    return (
        <Grid container spacing={0} className={classes.root}>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.value}>{value}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.label}>
                <Typography variant="body1" className={classes.label}>{label}</Typography>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(StatisticsTile)