import { Action, Reducer } from "redux";
import { KnownAction } from ".";
import { Settings } from "../../common/interfaces/Settings";

const defaultSettings: Settings = {
    defaultCountry: null,
    defaultTab: null,
    theme: "dark",
    dateFormat: null,
    topRanksColumns: [],
    forceOriginalDifficultyAttributes: false,
    highlightGlobalTopScores: true,
    showMapsInOldestDate: false,
    hideUnsnipableMaps: false,
    useOldColors: false
};

export const settingsReducer: Reducer<Settings> = (state: Settings = defaultSettings, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "GET_SETTINGS":
            const missingSettings = Object.keys(defaultSettings).filter(key => !Object.keys(action.payload).includes(key)) as (keyof Settings)[];
            let returnValue = { ...action.payload };
            missingSettings.forEach(s => {
                returnValue = {
                    ...returnValue,
                    [s]: defaultSettings[s]
                }
            });
            return returnValue;
        case "UPDATE_SETTINGS":
            return {
                ...action.payload
            };
        default:
            return state;
    };
};