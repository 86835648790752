import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, Typography, Card, CardContent } from '@material-ui/core';
import ToolBar from '../../common/components/ToolBar/ToolBar';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../store';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    toolbar: {
        height: 64
    },
    helpPaper: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    link: {
        color: theme.palette.text.primary
    }
});

type ClassKey = 'root' | 'toolbar' | 'helpPaper' | 'link';
type PropsType = IProps & WithStyles<ClassKey>

const HelpComponent: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const { countries } = useSelector((state: IApplicationState) => state);

    return (
        <div>
            <div className={classes.toolbar}>
                <ToolBar shortTitle="Help" longTitle="Help" />
            </div>
            <Card className={classes.helpPaper}>
                <CardContent>
                    <Typography align="left" variant="h6" paragraph>What am I looking at?</Typography>
                    <Typography align="left" variant="body1" paragraph>
                        You are looking at the osu!snipe website, a website mostly dedicated to showing data on country #1 scores in osu! The osu! website itself has very limited data on this matter (basically only a national top 50 on every beatmap), so this website was made in order to expand on this.
                        </Typography>
                    <Typography align="left" variant="body1" paragraph>
                        Stuff you can find here:
                        </Typography>
                    <Typography align="left" variant="body1" paragraph>
                        <ul>
                            <li>Who has the most country #1s in my country?</li>
                            <li>On what maps do I have a country #1 score?</li>
                            <li>Who recently sniped some of my country #1s?</li>
                            <li>And a lot more</li>
                        </ul>
                    </Typography>
                    <Typography align="left" variant="body1" paragraph>
                        This data is fetched from osu! automatically on a weekly basis. Manual updates are supported using the Discord bot; join the server if you want to do that or want to know more about the project in general.
                        </Typography>
                </CardContent>
            </Card>
            <Card className={classes.helpPaper}>
                <CardContent>
                    <Typography align="left" variant="h6" paragraph>Who made this?</Typography>
                    <Typography align="left" variant="body1" paragraph>
                        The creator of both the website and the Discord bot is Mr HeliX. You can find him here:
                        </Typography>
                    <Typography align="left" variant="body1" paragraph>
                        <ul>
                            <li><a className={classes.link} href="https://osu.ppy.sh/u/2330619">osu! profile</a></li>
                            <li><a className={classes.link} href="https://twitter.com/mister_helix">Twitter</a></li>
                            <li>Discord: Mr HeliX#0073</li>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.helpPaper}>
                <CardContent>
                    <Typography align="left" variant="h6" paragraph>Why is there no data for my country?</Typography>
                    <Typography align="left" variant="body1" paragraph>
                        Retrieving this data is an expensive task and requires someone living in that country to help out as well. Due to these limitations the number of countries is restricted. Currently the following countries are supported:
                        </Typography>
                    <Typography align="left" variant="body1" paragraph>
                        <ul>
                            {countries
                                .filter(c => c.code !== "global")
                                .map(c => c.country_name.includes("the ") ? { ...c, country_name: c.country_name.split('the ')[1] } : c)
                                .sort((a, b) => a.country_name < b.country_name ? -1 : 1)
                                .map(country => (
                                    <li>{country.country_name}</li>
                                ))}
                        </ul>
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.helpPaper}>
                <CardContent>
                    <Typography align="left" variant="h6" paragraph>Can you add my country?</Typography>
                    <Typography align="left" variant="body1" paragraph>
                        Depending on what country it is and how much room there's left I might be willing to add your country. Feel free to send me a message on Discord.
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.helpPaper}>
                <CardContent>
                    <Typography align="left" variant="h6" paragraph>Some of my #1s are missing your website sucks etc</Typography>
                    <Typography align="left" variant="body1" paragraph>
                        Read again. Data is updated once a week so yes of course some of your #1s might be missing. Join the Discord server and do some manual updates if you can't wait.
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.helpPaper}>
                <CardContent>
                    <Typography align="left" variant="h6" paragraph>I have this really cool idea for your website/bot, can you make it happen?</Typography>
                    <Typography align="left" variant="body1" paragraph>
                        Feel free to tell me all about it; if you read this whole thing you'll know where to find me :^)
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.helpPaper}>
                <CardContent>
                    <Typography align="left" variant="h6" paragraph>Who is Wessel?</Typography>
                    <Typography align="left" variant="body1" paragraph>
                        <a className={classes.link} href="https://osu.ppy.sh/users/4382220">Wessel's osu! profile</a>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default withStyles(styles)(HelpComponent)