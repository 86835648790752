import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import BaseTable from '../../../../common/components/BaseTable/BaseTable';
import MobileOnly from '../../../../common/components/MobileHelpers/MobileOnly';
import NoMobile from '../../../../common/components/MobileHelpers/NoMobile';
import { useHistory } from 'react-router-dom';
import { RankingsPlayer } from '../../../../common/interfaces/RankingsPlayer';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import { convertGamemodeToString } from '../../../../common/helpers/generic-helpers';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';

interface IProps {
    headers: { key: string, value: string }[];
    rows: { key: string | number, values: any }[];
    mainField: string;
    loading?: boolean;

    modsHandler?: (mods: string) => void;
    searchValue?: string;
    searchHandler?: (value: string) => void;
    hideSearchbar?: boolean;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    baseTable: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 224px)'
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: 'calc(100vh - 268px)'
        }
    },
    baseTableNoSearch: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 176px)'
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: 'calc(100vh - 220px)'
        }
    },
    filters: {
        marginBottom: theme.spacing(1)
    }
})

type ClassKey = 'root' | 'baseTable' | 'filters' | 'baseTableNoSearch';
type PropsType = IProps & WithStyles<ClassKey>

const RankingsTable: React.FC<PropsType> = (props) => {
    const { classes, headers, rows, mainField, loading, hideSearchbar } = props;
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const history = useHistory();
    const rankField = headers[0].key;
    const mobileHeaders = [rankField, 'name', mainField];

    const handleRowClick = (row: RankingsPlayer, inNewTab?: boolean) => {
        const path = `/player/${country.code === "all" ? row.country.toLowerCase() : country.code}/${gamemodeString}/${row.user_id}`;
        if (inNewTab) window.open(`https://snipe.huismetbenen.nl${path}`);
        else history.push(path);
    };

    if (loading) return <LoadingIcon />

    return (
        <>
            <MobileOnly>
                <BaseTable containerStyle={hideSearchbar ? classes.baseTableNoSearch : classes.baseTable} headers={headers.filter(h => mobileHeaders.includes(h.key))} rows={rows} handleClick={handleRowClick} />
            </MobileOnly>
            <NoMobile>
                <BaseTable containerStyle={hideSearchbar ? classes.baseTableNoSearch : classes.baseTable} headers={headers} rows={rows} handleClick={handleRowClick} />
            </NoMobile>
        </>
    );
}

export default withStyles(styles)(RankingsTable)