import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import ToolBar from '../../common/components/ToolBar/ToolBar';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    toolbar: {
        height: 64
    }
});

type ClassKey = 'root' | 'toolbar';
type PropsType = IProps & WithStyles<ClassKey>

const DonateComponent: React.FC<PropsType> = (props) => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <ToolBar shortTitle="Donate" longTitle="Donate" />
            </div>
            Donate
        </div>
    );
};

export default withStyles(styles)(DonateComponent)