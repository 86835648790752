import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, Box, CircularProgress, Typography } from '@material-ui/core';

interface IProps {
    variant: "determinate" | "indeterminate" | "static";
    value: number;
    label: string;
    color?: string;
    size?: number;
    thickness?: number;
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
});

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>

const LoadingIconWithLabel: React.FC<PropsType> = (props) => {
    const { variant, value, label, color, size, thickness } = props;
    const rootStyle = size ? { width: size, height: size } : undefined;
    const style = color ? { color } : undefined;

    return (
        <Box style={rootStyle} position="relative" display="inline-flex">
            <CircularProgress style={{ ...style, zIndex: 1 }} size={size || 40} thickness={thickness || 3.6} variant={variant} value={value} />
            <Box top={0} bottom={0} left={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress style={{ color: "darkgrey", opacity: 0.2 }} size={size || 40} thickness={thickness || 3.6} variant="static" value={100} />
                <Box top={0} bottom={0} left={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h6" component="div" style={style}>
                        {label}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default withStyles(styles)(LoadingIconWithLabel)