import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { CountryContext, GamemodeContext } from '../../../../common/context';
import { useFetch } from '../../../../common/hooks/useAPICall';
import BaseTable from '../../../../common/components/BaseTable/BaseTable';
import { convertGamemodeToString } from '../../../../common/helpers/generic-helpers';
import TableLinks from '../../../../common/components/OsuDirectLink/TableLinks';
import LoadingIcon from '../../../../common/components/LoadingIcon/LoadingIcon';
import moment from 'moment';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const UnplayedBeatmaps: React.FC<PropsType> = (props) => {
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const { response: beatmaps, loading } = useFetch<{ map_id: number, artist: string, title: string, diff_name: string, date_ranked: string, star_rating: number }[]>(`beatmaps/unplayed/all/${country.code}?mode=${gamemodeString}`);

    if (loading)
        return <LoadingIcon />;

    const headers = [
        { key: "index", value: "" },
        { key: "map_name", value: "Map" },
        { key: "sr", value: "Star rating" },
        { key: "date_ranked", value: "Date ranked" },
        { key: "links", value: "" }
    ];

    const rows = beatmaps.map((map, index) => ({
        key: index + 1,
        values: {
            index: index + 1,
            map_name: `${map.artist} - ${map.title} [${map.diff_name}]`,
            sr: Math.round(map.star_rating * 100) / 100,
            links: <TableLinks osuDirect mapId={map.map_id} />,
            ...map,
            date_ranked: moment(map.date_ranked).format("YYYY-MM-DD HH:mm"),
        }
    }));

    const onRowClick = (map: any) => {
        window.open(`https://osu.ppy.sh/b/${map.map_id}`);
    };

    return (
        <BaseTable headers={headers} rows={rows} handleClick={onRowClick} />
    );
};

export default withStyles(styles)(UnplayedBeatmaps)