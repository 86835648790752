import React, { useState, ChangeEvent, useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme, TextField } from '@material-ui/core';

interface IProps {
    label: string;
    value: string;
    styling?: any;
    update: (searchValue: string) => void;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const Searchbar: React.FC<PropsType> = (props) => {
    const { styling, label, value, update } = props;
    const [searchValue, setSearchValue] = useState(value);

    useEffect(() => setSearchValue(value), [value]);

    const searchValueChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        update(event.target.value.toLowerCase());
    };

    return (
        <TextField className={styling} label={label} value={searchValue} onChange={searchValueChanged} />
    )
}

export default withStyles(styles)(Searchbar)