import devConfig from "./dev";
import stagingConfig from "./staging";
import prodConfig from "./prod";

export interface IConfig {
    env: string,
    api: string
};

let config: IConfig;
const hostName = window.location.hostname;

if (hostName === "localhost") config = devConfig;
else if (hostName === "snipe.staging.huismetbenen.nl") config = stagingConfig;
else config = prodConfig;

export default config;