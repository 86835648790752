import { IAppThunkAction } from "..";
import { Settings } from "../../common/interfaces/Settings";
import { IGetSettingsAction, IUpdateSettingsAction } from "./actions";
import localforage from "localforage";

export type KnownAction = IGetSettingsAction | IUpdateSettingsAction;

export const settingsActions = {
    getSettings: (): IAppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            const settings = await localforage.getItem<Settings>("settings");
            if (settings)
                dispatch({ type: "GET_SETTINGS", payload: settings });
            else
                settingsActions.updateSettings({
                    defaultCountry: "nl",
                    defaultTab: "weighted-pp",
                    theme: "dark",
                    dateFormat: "ymd",
                    topRanksColumns: ["rank", "map_name", "mods", "sr_label", "accuracy_label", "count_100", "count_50", "count_miss", "pp_label", "links"],
                    forceOriginalDifficultyAttributes: false,
                    highlightGlobalTopScores: true,
                    showMapsInOldestDate: false,
                    hideUnsnipableMaps: false,
                    useOldColors: false
                })(dispatch, getState);
        }
        catch (error) {
            console.error(error);
        };
    },
    updateSettings: (settings: Settings): IAppThunkAction<KnownAction> => async (dispatch) => {
        try {
            await localforage.setItem("settings", settings);
            dispatch({
                type: "UPDATE_SETTINGS",
                payload: settings
            });
        }
        catch (error) {
            console.error(error);
        }
        return Promise.resolve();
    }
};