import React, { useContext, useEffect, useState } from "react";
import { withStyles, WithStyles, createStyles, Theme, Typography, Paper, Button } from "@material-ui/core";
import ModsSelector from "../../../../common/components/ModsSelector/ModsSelector";
import { Mods } from "../../../../common/interfaces/Mods";
import TopRanksTable from "./TopRanksTable";
import { PlayerContext } from "../../../../common/context";
import MultiSelectBox from "../../../../common/components/SelectBoxes/MultiSelectBox";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../store";
import { ParsedTopRank } from "../../../../common/interfaces/Beatmap";
import localforage from "localforage";
import TopRanksCollectionDialog from "./TopRanksCollectionDialog";

interface ColumnOption {
    id: keyof ParsedTopRank | `beatmap.${keyof ParsedTopRank["beatmap"]}` | "links";
    label: string;
    default?: boolean;
    disabled?: boolean;
    sortable?: boolean;
    maxWidth?: string;
};

interface IProps { };

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        textAlign: "left"
    },
    paper: {
        paddingTop: theme.spacing(1)
    },
    title: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    selectBoxStyling: {
        [theme.breakpoints.up("md")]: {
            width: "20%",
        }
    },
    topRanksOptions: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1)
    }
});

type ClassKey = "root" | "paper" | "title" | "selectBoxStyling" | "topRanksOptions";
type PropsType = IProps & WithStyles<ClassKey>;

const TopRanks: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const { settings } = useSelector((state: IApplicationState) => state);
    const player = useContext(PlayerContext);
    const [selectedColumns, setSelectedColumns] = useState<{ id: string, label: string, sortable?: boolean }[]>([]);
    const [selectedMods, setMods] = useState<string>("");
    const [loadingDefaults, setLoadingDefaults] = useState<boolean>(true);
    const [collectionDialogOpen, setCollectionDialogOpen] = useState<boolean>(false);

    const [columnOptions, setColumnOptions] = useState<ColumnOption[]>(
        [
            { id: "rank", label: "Rank", default: true, disabled: true },
            { id: "map_name", label: "Map", default: true, disabled: true, sortable: false },
            { id: "mods", label: "Mods", default: true, sortable: true, maxWidth: "1%" },
            { id: "sr_label", label: "SR", default: true, sortable: true, maxWidth: "1%" },
            { id: "bpm", label: "BPM", sortable: true, maxWidth: "1%" },
            { id: "ar", label: "AR", sortable: true, maxWidth: "1%" },
            { id: "cs", label: "CS", sortable: true, maxWidth: "1%" },
            { id: "od", label: "OD", sortable: true, maxWidth: "1%" },
            { id: "hp", label: "HP", sortable: true, maxWidth: "1%" },
            { id: "total_length_label", label: "Length", sortable: true, maxWidth: "1%" },
            { id: "score", label: "Score", sortable: true, maxWidth: "1%" },
            { id: "accuracy_label", label: "Accuracy", default: true, sortable: true, maxWidth: "1%" },
            { id: "count_100", label: "100", default: true, sortable: true, maxWidth: "1%" },
            { id: "count_50", label: "50", default: true, sortable: true, maxWidth: "1%" },
            { id: "count_miss", label: "Miss", default: true, sortable: true, maxWidth: "1%" },
            { id: "pp_label", label: "pp", default: true, sortable: true, maxWidth: "5%" },
            { id: "date_set_label", label: "Date set", sortable: true, maxWidth: "6%" },
            { id: "date_ranked_label", label: "Date ranked", sortable: true, maxWidth: "7%" },
            { id: "beatmap.count_normal", label: "Circles", sortable: false, maxWidth: "1%" },
            { id: "beatmap.count_slider", label: "Sliders", sortable: false, maxWidth: "1%" },
            { id: "beatmap.count_spinner", label: "Spinners", sortable: false, maxWidth: "1%" },
            { id: "links", label: "osu!direct", default: true, maxWidth: "1%" }
        ]
    );

    useEffect(() => {
        if (settings.topRanksColumns?.length)
            setColumnOptions(columns => columns.map(c => ({ ...c, default: settings.topRanksColumns.includes(c.id) })));
        setLoadingDefaults(false);
    }, [settings.topRanksColumns]);

    useEffect(() => {
        setSelectedColumns(columnOptions.filter(c => c.default));
    }, [columnOptions]);

    const handleModsChange = (mods: Mods): void => {
        let modsString = "";
        Object.keys(mods).forEach((mod) => {
            if (mods[mod as keyof Mods])
                modsString += mod;
        });
        setMods(modsString);
    };

    const handleColumnsChange = (columns: string[]): void => {
        setSelectedColumns(columnOptions.filter(c => columns.includes(c.id)));
        localforage.setItem("settings", { ...settings, topRanksColumns: columns });
    };

    const topRanksCount = selectedMods
        ? [selectedMods, selectedMods.concat("SD"), selectedMods.concat("PF")].reduce((total, current) => total + (player.mods_count[current] ?? 0), 0)
        : player.count_total;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h6" className={classes.title}>
                    Top Ranks
                </Typography>
                <Button onClick={() => setCollectionDialogOpen(true)} variant="contained" color="primary" style={{ margin: 8 }}>
                    Write to collection.db
                </Button>

                <div className={classes.topRanksOptions}>
                    <ModsSelector update={handleModsChange} shouldApply />
                    {!loadingDefaults && <MultiSelectBox label="Columns" items={columnOptions} handleValueChange={handleColumnsChange} selectBoxStyling={classes.selectBoxStyling} />}
                </div>
                <TopRanksTable topRanksCount={topRanksCount} selectedColumns={selectedColumns} selectedMods={selectedMods} />
            </Paper>

            {collectionDialogOpen && <TopRanksCollectionDialog open={collectionDialogOpen} onClose={() => setCollectionDialogOpen(false)} />}
        </div>
    );
};

export default withStyles(styles)(TopRanks);