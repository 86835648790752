import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, CircularProgress } from '@material-ui/core';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        paddingTop: theme.spacing(2),
        width: "100%",
        display: "flex",
        justifyContent: "center"
    }
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const LoadingIcon: React.FC<PropsType> = (props) => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
};

export default withStyles(styles)(LoadingIcon)