import i18next, { FallbackLngObjList } from "i18next";

const localeResources = {
    "en-US": {
        common: require("./common/translations/en-US/common.json"),
        playerDetails: require("./modules/PlayerDetails/translations/playerDetails.json")
    }
};

const fallbackLanguages: FallbackLngObjList = {
    default: ["en-US"]
};

const namespaces = Object.keys(localeResources["en-US"]);

const i18n = i18next;
i18n.init({
    resources: localeResources,
    fallbackLng: fallbackLanguages,
    ns: namespaces,
    defaultNS: "common",
    react: { wait: true },
    cache: { enabled: true }
});

export default i18n;