import React, { ChangeEvent, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

interface IProps {
    label: string;
    items: { id: string, label: string }[];
    defaultValue?: string;
    handleValueChange: (newValue: string) => void;
    selectBoxStyling: any;
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    selectBox: {
        textAlign: 'left'
    }
});

type ClassKey = 'root' | 'selectBox';
type PropsType = IProps & WithStyles<ClassKey>

const BasicSelectBox: React.FC<PropsType> = (props) => {
    const { classes, label, items, defaultValue, handleValueChange, selectBoxStyling } = props;
    const [value, setValue] = useState(defaultValue);

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
        handleValueChange(event.target.value as string);
    };

    return (
        <FormControl className={selectBoxStyling}>
            <InputLabel>{label}</InputLabel>
            <Select className={classes.selectBox} value={value} onChange={handleChange}>
                {items.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default withStyles(styles)(BasicSelectBox)