import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { ChartType, ChartOptions } from 'chart.js';
import BaseChart from '../BaseChart/BaseChart';
import moment from "moment";
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../store';
import { convertDateFormat } from '../../../helpers/generic-helpers';

interface IProps {
    labels: string[],
    datasets: { label: string, data: number[] }[],
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
});

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const AreaChart: React.FC<PropsType> = (props) => {
    const { labels, datasets } = props;
    const { settings } = useSelector((state: IApplicationState) => state);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const chartType: ChartType = "line";
    const dateFormat = convertDateFormat(settings.dateFormat);

    const options: ChartOptions = {
        maintainAspectRatio: false,
        tooltips: {
            intersect: false,
            callbacks: {
                title: (tooltipItems, data): string => {
                    return moment(tooltipItems[0].xLabel).format(dateFormat);
                },
            }
        },
        scales: {
            xAxes: [
                {
                    type: "time",
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: isSmallScreen ? 10 : 20
                    },
                    gridLines: {
                        display: false
                    }
                }
            ]
        },
        legend: {
            display: false
        }
    };

    const data = {
        labels,
        datasets: datasets.map(set => ({
            ...set,
            fill: true
        }))
    };

    return <BaseChart data={data} type={chartType} options={options} />
};

export default withStyles(styles)(AreaChart)