import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { PlayerContext } from '../../../../../common/context';
import LoadingIconWithLabel from '../../../../../common/components/LoadingIcon/LoadingIconWithLabel';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../../store';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 80,
            justifyContent: "space-around",
        },
    },
    favouriteMod: {
        marginTop: theme.spacing(0.5),
        [theme.breakpoints.up('sm')]: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(8)
        }
    },
    modPercentage: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3)
        },
        fontWeight: "bold"
    }
});

type ClassKey = "root" | "favouriteMod" | "modPercentage";
type PropsType = IProps & WithStyles<ClassKey>;

const FavouriteMods: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const player = useContext(PlayerContext);
    const { useOldColors } = useSelector((state: IApplicationState) => state.settings);
    const favouriteModsLabels = Object.keys(player.favourite_mods_count || {}).sort((a, b) => player.favourite_mods_count[b] - player.favourite_mods_count[a]).slice(0, 4);
    const favouriteModsData = favouriteModsLabels.map(m => +(player.favourite_mods_count[m] / player.count_total * 100).toFixed(1));
    const colors = [
        { mod: "nomod", color: useOldColors ? "#f7a35c" : "#F47A1F" },
        { mod: "HD", color: useOldColors ? "#e4d354" : "#FDBB2F" },
        { mod: "HR", color: useOldColors ? "#f45b5b" : "#ba2f2f" },
        { mod: "DT", color: useOldColors ? "#8085e9" : "#28cddc" },
        { mod: "FL", color: useOldColors ? "#90ed7d" : "#7AC142" }
    ];

    return (
        <div className={classes.root}>
            {favouriteModsLabels.map((label, index) => (
                <div key={index} className={classes.favouriteMod}>
                    <LoadingIconWithLabel
                        variant="static"
                        value={favouriteModsData[index]}
                        label={label === "nomod" ? "NM" : label}
                        size={85}
                        thickness={4.5}
                        color={colors.find(c => c.mod === label)?.color || (useOldColors ? "#2b908f" : "#7AC142")}
                    />
                    <Typography variant="body1" className={classes.modPercentage}>{favouriteModsData[index]}%</Typography>
                </div>
            ))}
        </div>
    );
};

export default withStyles(styles)(FavouriteMods);