import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga";
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducers } from './store';
import MainContainer from './common/components/MainContainer/MainContainer';

const trackingId = "UA-149685557-1";
ReactGA.initialize(trackingId);

const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk))(createStore);
const store = createStoreWithMiddleware(combineReducers(reducers));

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                <BrowserRouter>
                    <MainContainer />
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default App;
