import React, { MouseEvent } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

interface IProps {
    mapId: number;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    beatmapPageButton: {
        maxHeight: 0
    }
})

type ClassKey = 'root' | 'beatmapPageButton';
type PropsType = IProps & WithStyles<ClassKey>

const BeatmapPageLink: React.FC<PropsType> = (props) => {
    const { classes, mapId } = props;

    const handleBeatmapPageClick = (event: MouseEvent, map_id: number): void => {
        event.stopPropagation();
        window.open(`https://osu.ppy.sh/b/${map_id}`);
    };

    return (
        <Button
            className={classes.beatmapPageButton}
            style={{ maxWidth: 24, maxHeight: 24, minWidth: 24, minHeight: 24 }}
            size="small"
            color="primary"
            onClick={(event) => handleBeatmapPageClick(event, mapId)}><OpenInNewIcon />
        </Button>
    );
};

export default withStyles(styles)(BeatmapPageLink)