import React, { useState, MouseEvent } from 'react';
import { withStyles, WithStyles, createStyles, Theme, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, TablePagination, CircularProgress, TableSortLabel } from '@material-ui/core';
import MobileOnly from '../MobileHelpers/MobileOnly';
import NoMobile from '../MobileHelpers/NoMobile';
import _ from 'lodash';

interface IProps {
    headers: { key: string, value: string, maxWidth?: string, sortable?: boolean }[];
    rows: { key: string | number, values: any, style?: any }[];
    containerStyle?: any;
    tableStyle?: any;
    handleClick?: (row: any, inNewTab?: boolean) => void;
    currentPage?: number;
    handlePageNumberChange?: (page: number) => void;
    hidePagination?: boolean;
    sortBy?: string;
    sortingHandler?: (newField: any) => void;
    order?: "asc" | "desc";
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    headerCell: {
        fontWeight: 'bold'
    },
    loadingIcon: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
});

type ClassKey = 'root' | 'headerCell' | 'loadingIcon';
type PropsType = IProps & WithStyles<ClassKey>

const BaseTable: React.FC<PropsType> = (props) => {
    const { containerStyle, tableStyle } = props;
    return (
        <TableContainer className={containerStyle} component={Paper}>
            <MobileOnly>
                <Table size='small' stickyHeader className={tableStyle}>
                    <BaseTableContent {...props} />
                </Table>
            </MobileOnly>
            <NoMobile>
                <Table size="small" stickyHeader>
                    <BaseTableContent {...props} />
                </Table>
            </NoMobile>
        </TableContainer>
    )
}

const BaseTableContent: React.FC<PropsType> = (props) => {
    const { classes, headers, rows, handleClick, currentPage, handlePageNumberChange, hidePagination, sortBy, sortingHandler, order } = props;
    const [currentPageLocal, setCurrentPageLocal] = useState(0);
    const [rowsPerPage] = useState(50);

    const handlePageChange = (_: any, newPage: number) => {
        if (handlePageNumberChange) handlePageNumberChange(newPage);
        else setCurrentPageLocal(newPage);
    };

    const handleRowClick = (row: any, auxClick?: boolean) => (event: MouseEvent) => {
        if (auxClick && event.button !== 1) return;
        if (!!handleClick)
            handleClick(row, !!event.ctrlKey || auxClick);
    };

    const cPage = currentPage ?? currentPageLocal;

    const slicedRows = rowsPerPage > 0
        ? rows.slice(cPage * rowsPerPage, cPage * rowsPerPage + rowsPerPage)
        : rows;

    const getCellStyling = (width: string | undefined, index: number): any => {
        const style: any = { width, paddingLeft: 5, paddingRight: 5 };
        if (index === 0) delete style.paddingLeft;
        if (index === headers.length - 1) delete style.paddingRight;
        return style;
    };

    const BaseTablePagination = () => <TablePagination
        rowsPerPageOptions={[50]}
        colSpan={headers.length}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={cPage}
        onChangePage={handlePageChange}
        labelDisplayedRows={({ count, from, page, to }) => `Page ${page + 1}/${Math.ceil(count / 50)} (${from.toLocaleString()}-${to.toLocaleString()})`}
    />;

    return (
        <>
            <TableHead>
                {!hidePagination && (
                    <TableRow>
                        <BaseTablePagination />
                    </TableRow>
                )}
                <TableRow>
                    {headers.map((header, i) => (
                        <TableCell className={classes.headerCell} key={header.key} style={getCellStyling(header.maxWidth, i)}>
                            {sortingHandler && header.sortable ? (
                                <TableSortLabel active={sortBy === header.key} direction={sortBy === header.key ? order : "desc"} onClick={() => sortingHandler(header.key)}>
                                    {header.value}
                                </TableSortLabel>
                            ) : header.value}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {slicedRows.length && slicedRows[0].key === undefined
                    ? <TableRow><TableCell><CircularProgress className={classes.loadingIcon} /></TableCell></TableRow>
                    : slicedRows.map((row, index) => (
                        <TableRow key={row.key || index} hover={true}
                            onClick={handleRowClick(row.values)}
                            onWheel={handleRowClick(row.values, true)}
                            onAuxClick={handleRowClick(row.values, true)} style={row.style}>
                            {headers.map((header, i) => (
                                <TableCell style={getCellStyling(header.maxWidth, i)} key={`${row.key}-${i}`}>{_.get(row.values, header.key)}</TableCell>
                            ))}
                        </TableRow>
                    ))
                }
            </TableBody>
            {!hidePagination && (<TableFooter>
                <TableRow>
                    <BaseTablePagination />
                </TableRow>
            </TableFooter>)}
        </>
    )
}

export default withStyles(styles)(BaseTable)