import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, Typography } from '@material-ui/core';
import BetterTooltip from '../../../../common/components/BetterTooltip/BetterTooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IProps {
    headerText: string;
    tooltipText: string[];
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    headerText: {
        textAlign: "left",
        marginLeft: theme.spacing(2)
    },
    helpIcon: {
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(0.5)
    }
})

type ClassKey = "root" | "headerText" | "helpIcon";
type PropsType = IProps & WithStyles<ClassKey>

const PlayerDetailsChartHeader: React.FC<PropsType> = (props) => {
    const { classes, headerText, tooltipText } = props;
    return (
        <div className={classes.root}>
            <Typography className={classes.headerText} variant="h6">{headerText}</Typography>
            <BetterTooltip title={tooltipText}>
                <HelpOutlineIcon className={classes.helpIcon} />
            </BetterTooltip>
        </div>
    );
}

export default withStyles(styles)(PlayerDetailsChartHeader)