import config from "../config"
import { useState, useEffect } from "react";

export const useFetch = <T>(endpoint: string, skip: boolean = false): { response: T, loading: boolean } => {
    const [response, setResponse] = useState<T>({} as T);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const res = await fetch(`${config.api}/${endpoint}`, {
                method: 'GET',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                })
            });
            const json = await res.json();
            setResponse(json);
            setIsLoading(false);
        }
        if (skip) setIsLoading(false);
        else fetchData();
    }, [endpoint, skip])

    return { response, loading: isLoading };
}