export enum BeatmapRankedStatus {
    RANKED = 1,
    APPROVED = 2,
    QUALIFIED = 3,
    LOVED = 4
};

export interface SqlTopRank {
    uid: number,
    osu_score_id: number,
    player_id: number,
    username: string,
    country: string,
    score: number,
    pp: number,
    sr: number,
    accuracy: number,
    count_300: number,
    count_100: number,
    count_50: number,
    count_miss: number,
    date_set: string,
    mods: string,
    max_combo: number,
    ar: number,
    cs: number,
    od: number,
    hp: number,
    bpm: number,
    is_global: boolean,
    beatmap: {
        map_id: number,
        set_id: number,
        artist: string,
        title: string,
        diff_name: string,
        total_length: number,
        date_ranked: string,
        count_normal: number,
        count_slider: number,
        count_spinner: number,
        ranked_status: BeatmapRankedStatus,
        ar: number,
        cs: number,
        od: number,
        hp: number,
        bpm: number,
        max_combo: number
    },
    date_ranked: string,
    total_length: number
};

export type TopRank = {
    map_id: number;
    set_id: number;
    title: string;
    artist: string;
    diff_name: string;
    ar: number;
    cs: number;
    hp: number;
    od: number;
    bpm: number;
    length: number;
    new_star_ratings: StarRatings;
    date_ranked: string;
    count_normal: number;
    count_slider: number;
    count_spinner: number;
    top_nl?: TopPlayer;
    top_aus?: TopPlayer;
    top_usa?: TopPlayer;
    top_spain?: TopPlayer;
    top_uk?: TopPlayer;
    top_be?: TopPlayer;
    top_ca?: TopPlayer;
    top_fr?: TopPlayer;
    top_pl?: TopPlayer;
    top_chile?: TopPlayer;
    top_se?: TopPlayer;
    top_de?: TopPlayer;
    top_global?: TopPlayer;
};

export type ParsedTopRank = SqlTopRank & {
    rank: number;
    map_name: string;
    weighted_pp: number;
    sr_label: string;
    pp_label: string;
    accuracy_label: string;
    date_set_label: string;
    date_ranked_label: string;
    is_global_topscore: boolean;
    total_length_label: string;
};

export type TopPlayer = {
    player_id: number;
    player: string;
    player_lower_case: string;
    pp: number;
    accuracy: number;
    count_100: number;
    count_50: number;
    count_miss: number;
    mods: string;
    tie: boolean;
    date_set: string;
    sr: number;
    score: number;
};

export type StarRatings = {
    [key in StarRatingMods]: number;
};

export type StarRatingMods =
    'NM' |
    'EZ' |
    'HR' |
    'DT' |
    'HT' |
    'EZDT' |
    'EZHT' |
    'HRDT' |
    'HRHT';

export type TopPlayerField =
    'top_nl' |
    'top_aus' |
    'top_usa' |
    'top_spain' |
    'top_uk' |
    'top_be' |
    'top_ca' |
    'top_fr' |
    'top_pl' |
    'top_chile' |
    'top_se' |
    'top_de' |
    'top_global';

export interface SqlScore {
    uid: number,
    osu_score_id: number,
    map_id: number,
    player_id: number,
    username?: string,
    country: string,
    score: number,
    pp: number,
    sr: number,
    accuracy: number,
    count_300: number,
    count_100: number,
    count_50: number,
    count_miss: number,
    date_set: string,
    mods: string,
    max_combo: number,
    ar: number,
    cs: number,
    od: number,
    hp: number,
    bpm: number
};