import React, { useContext, useState } from "react";
import { withStyles, WithStyles, createStyles, Theme, Accordion, AccordionSummary, Typography, AccordionDetails } from "@material-ui/core";
import moment, { Moment } from "moment";
import { useFetch } from "../../../../common/hooks/useAPICall";
import { CountryContext, PlayerContext } from "../../../../common/context";
import LoadingIcon from "../../../../common/components/LoadingIcon/LoadingIcon";
import { SqlChange } from "../../../../common/interfaces/RecentChange";
import BaseTable from "../../../../common/components/BaseTable/BaseTable";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { round } from "lodash";

interface IProps {
    since: Moment,
    until: Moment,
    includeOwnSnipes: boolean
};

type ClassKey = "table" | "details";
type PropsType = IProps & WithStyles<ClassKey>;
const styles = (theme: Theme) => createStyles<ClassKey, Record<string, any>>({
    details: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    table: {
        width: "300%"
    }
});

const RecentChangesOld: React.FC<PropsType> = (props) => {
    const { classes, since, until, includeOwnSnipes } = props;
    const player = useContext(PlayerContext);
    const { code } = useContext(CountryContext);
    const [expanded, setExpanded] = useState<boolean>(false);
    const { response, loading } = useFetch<SqlChange[]>(`changes/old/${player.user_id}?since=${since.toISOString()}&until=${until.toISOString()}&includeOwnSnipes=${includeOwnSnipes}&isGlobal=${code === "global"}`, !expanded);

    const headers = [
        { key: 'index', value: '' },
        { key: 'map', value: "Map name" },
        { key: "sniper_name", value: "Sniper" },
        { key: "mods", value: "Mods" },
        { key: "sr", value: "SR" },
        { key: "accuracy", value: "Accuracy" },
        { key: "date_set", value: "Date (UTC)" }
    ];

    const changes = Array.isArray(response) ? response : [];

    const rows = changes.map((change, index) => ({
        key: index,
        values: {
            ...change,
            index: index + 1,
            mods: change.mods === "nomod" ? "" : change.mods,
            sr: `${round(change.sr ?? 0, 2)}*`,
            map: "map" in change ? change.map : `${change.artist} - ${change.title} [${change.diff_name}]`,
            date_set: moment(change.date_set).format("YYYY-MM-DD HH:mm"),
            accuracy: !!change.accuracy ? `${round(change.accuracy * 100, 2)}%` : "?",
        }
    }));

    const handleRowClick = (row: any): void => {
        window.open(row.osu_score_id
            ? `https://osu.ppy.sh/scores/osu/${row.osu_score_id}`
            : `https://osu.ppy.sh/b/${row.map_id}`);
    };

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Recently lost #1s</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {loading
                    ? <LoadingIcon />
                    : <BaseTable tableStyle={classes.table} headers={headers} rows={rows} handleClick={handleRowClick} />
                }
            </AccordionDetails>
        </Accordion>
    );
};

export default withStyles(styles)(RecentChangesOld);