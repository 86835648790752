import { Gamemode } from "../interfaces/Gamemode";
import { Mods } from "../interfaces/Mods";
import { DateFormat } from "../interfaces/Settings";

export const formatNumber = (value: number): string => {
    const [number, decimals] = value?.toString().split('.') || ["", ""];
    const splitNumber = number.split('').reverse();
    return splitNumber.reduce((total, current, index) => {
        return index % 3 === 0 && index > 0
            ? `${total},${current}`
            : `${total}${current}`;
    }, '').split('').reverse().join('').concat(decimals ? `.${decimals}` : '');
};

export const convertStringToGamemode = (gamemode: string): Gamemode => {
    switch (gamemode) {
        case "osu": return Gamemode.Osu;
        case "taiko": return Gamemode.Taiko;
        case "catch": return Gamemode.Catch;
        case "mania": return Gamemode.Mania;
        default:
            console.error(`Unknown gamemode: ${gamemode}`);
            return Gamemode.Osu;
    };
};

export const convertGamemodeToString = (gamemode: Gamemode): string => {
    switch (gamemode) {
        case Gamemode.Osu: return "osu";
        case Gamemode.Taiko: return "taiko";
        case Gamemode.Catch: return "catch";
        case Gamemode.Mania: return "mania";
        default:
            console.error(`Unknown gamemode: ${gamemode}`);
            return "";
    };
};

export const convertDateFormat = (format: DateFormat): string => {
    switch (format) {
        case "ymd": return "YYYY-MM-DD";
        case "dmy": return "DD-MM-YYYY";
        case "mdy": return "MM-DD-YYYY";
        default: return "YYYY-MM-DD";
    };
};

export const convertModsToModsString = (mods: Mods): string => {
    return Object.keys(mods).reduce((total, current) => {
        if (mods[current as keyof Mods])
            return total + current;
        return total;
    }, '');
};

export const startDownload = (url: string, title: string, extension: string = "json"): void => {
    const d = document.createElement("a");
    d.setAttribute("href", url);
    d.setAttribute("download", `${title}.${extension}`);
    d.click();
};
