import React, { useState, MouseEvent } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Menu, MenuItem, FormControlLabel, Checkbox, IconButton } from '@material-ui/core';
import { ModsPieChartOptions } from '../../../modules/PlayerDetails/components/PlayerDetailsCharts/ModsPieChart/ModsPieChartOptionsContainer';
import MenuIcon from '@material-ui/icons/Menu';
import i18n from '../../../i18n';

interface IProps {
    options: ModsPieChartOptions,
    onChange: (options: ModsPieChartOptions) => void
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    formControlLabel: {
        width: "100%",
        height: "100%"
    }
})

type ClassKey = 'root' | "formControlLabel";
type PropsType = IProps & WithStyles<ClassKey>

const CheckboxMenu: React.FC<PropsType> = (props) => {
    const { classes, options, onChange } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const optionChanged = (option: keyof ModsPieChartOptions): void => {
        const newOptions = {
            ...options,
            [option]: !options[option]
        };
        onChange(newOptions);
    };

    const optionKeys = Object.keys(options) as (keyof ModsPieChartOptions)[];

    return (
        <>
            <IconButton onClick={openMenu}>
                <MenuIcon />
            </IconButton>
            <Menu open={Boolean(anchorEl)} onClose={closeMenu} anchorEl={anchorEl} keepMounted>
                {optionKeys.map(option => (
                    <MenuItem key={option}>
                        <FormControlLabel
                            className={classes.formControlLabel}
                            control={<Checkbox checked={options[option]} color="primary" onClick={() => optionChanged(option)} />}
                            label={i18n.t(`modsPieChartOptions.${option}`)}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default withStyles(styles)(CheckboxMenu)