import React, { useContext } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import ToolBar from '../../common/components/ToolBar/ToolBar';
import { CountryContext, GamemodeContext } from '../../common/context';
import { Switch, Route, Redirect } from 'react-router-dom';
import RankingsNavigation from './components/RankingsNavigation/RankingsNavigation';
import WeightedPPRankings from './components/RankingsPages/WeightedPPRankings';
import CountRankings from './components/RankingsPages/CountRankings';
import AveragePPRankings from './components/RankingsPages/AveragePPRankings';
import AverageSRRankings from './components/RankingsPages/AverageSRRankings';
import AverageAccuracyRankings from './components/RankingsPages/AverageAccuracyRankings';
import OldestDateRankings from './components/RankingsPages/OldestDateRankings';
import StatisticsPanel from '../../common/components/StatisticsPanel/StatisticsPanel';
import { useFetch } from '../../common/hooks/useAPICall';
import CustomRankings from './components/RankingsPages/CustomRankings';
import UnplayedBeatmaps from './components/RankingsPages/UnplayedBeatmaps';
import AverageScoreRankings from './components/RankingsPages/AverageScoreRankings';
import { convertGamemodeToString } from '../../common/helpers/generic-helpers';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../store';
import SnipesRankings from './components/RankingsPages/Snipes/SnipesRankings';
import TotalScoreRankings from './components/RankingsPages/TotalScoreRankings';
import { CountryStats } from '../../common/interfaces/CountryStatistics';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    toolbar: {
        height: '64px'
    },
    statisticsPanel: {
        marginBottom: theme.spacing(1)
    }
})

type ClassKey = 'root' | 'toolbar' | 'statisticsPanel';
type PropsType = IProps & WithStyles<ClassKey>

const Rankings: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const { settings } = useSelector((state: IApplicationState) => state);
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const { response: statistics, loading } = useFetch<CountryStats>(`rankings/${country.code}/statistics?mode=${gamemodeString}`);
    const countryName = country.code === 'global' ? 'Global' : country.country_name;

    const statisticsTiles: { label : string, value: string | number | null, hover?: string | number | null }[] = !loading
        ? [
            { label: 'Unplayed maps', value: statistics.unplayed_count },
            { label: 'Country coverage', value: `${Math.round(statistics.coverage * 10000) / 100}%` }
        ]
        : [];

    if (statisticsTiles.length && statistics.most_gained_player_id && statistics.most_lost_player_id)
        statisticsTiles.push(...[
            { label: 'Most gained', value: statistics.most_gained_player_name, hover: statistics.most_gained_count },
            { label: 'Most losses', value: statistics.most_lost_player_name, hover: statistics.most_lost_count }
        ]);

    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <ToolBar shortTitle={countryName} longTitle={`#1 country leaderboards - ${countryName}`} />
            </div>
            <div className={classes.statisticsPanel}>
                <StatisticsPanel xs={2} sm={2} md={2} lg={1} mainLabel='General statistics' tiles={country.code === "all" ? [] : statisticsTiles} />
            </div>
            <RankingsNavigation />
            <Switch>
                <Route path={`/rankings/${country.code}/${gamemodeString}/weighted-pp`} component={WeightedPPRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/count`} component={CountRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/average-pp`} component={AveragePPRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/average-sr`} component={AverageSRRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/average-acc`} component={AverageAccuracyRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/average-score`} component={AverageScoreRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/total-score`} component={TotalScoreRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/oldest`} component={OldestDateRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/snipes`} component={SnipesRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/custom`} component={CustomRankings} />
                <Route path={`/rankings/${country.code}/${gamemodeString}/unplayed`} component={UnplayedBeatmaps} />
                <Route path={`/rankings/${country.code}`}>
                    <Redirect to={`/rankings/${country.code}/${gamemodeString}/${settings.defaultTab}`} />
                </Route>
                <Route path={`/rankings/${country.code}/${gamemodeString}`}>
                    <Redirect to={`/rankings/${country.code}/${gamemodeString}/${settings.defaultTab}`} />
                </Route>
            </Switch>
        </div>
    )
}

export default withStyles(styles)(Rankings)