import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import CheckboxMenu from '../../../../../common/components/CheckboxMenu/CheckboxMenu';

interface IProps {
    optionsChanged: (options: ModsPieChartOptions) => void
};

export interface ModsPieChartOptions {
    combineNightcore: boolean,
    ignoreSuddenDeathAndPerfect: boolean,
    ignoreNoFail: boolean,
    ignoreSpunOut: boolean,
    showLabels: boolean
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const ModsPieChartOptionsContainer: React.FC<PropsType> = (props) => {
    const { optionsChanged } = props;

    const newOptions: ModsPieChartOptions = {
        combineNightcore: false,
        ignoreSuddenDeathAndPerfect: false,
        ignoreNoFail: false,
        ignoreSpunOut: false,
        showLabels: true
    };

    const [options, setOptions] = useState<ModsPieChartOptions>(newOptions);

    const updateOptions = (options: ModsPieChartOptions): void => {
        setOptions(options);
        optionsChanged(options);
    };

    return (
        <CheckboxMenu options={options} onChange={updateOptions} />
    );
}

export default withStyles(styles)(ModsPieChartOptionsContainer)