import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import clsx from "clsx";
import OldDonatorIcon from "./donator_old.png";
import CountryFlagIcon from '../CountryFlagIcon/CountryFlagIcon';
import cuphead from "../../images/cuphead.png";
import BetterTooltip from '../BetterTooltip/BetterTooltip';
import { Variant } from '@material-ui/core/styles/createTypography';

interface IProps {
    user_id: number;
    name: string;
    country?: string;
    donator?: boolean;
    variant?: Variant;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        display: "flex",
        alignItems: "center"
    },
    commanderColor: {
        color: "cornflowerblue"
    },
    donatorColor: {
        color: "goldenrod"
    },
    starIconContainer: {
        maxHeight: "20px",
        position: "relative",
        bottom: "1px",
    },
    starIcon: {
        maxHeight: "20px"
    },
    oldDonatorIcon: {
        position: "relative",
        marginLeft: theme.spacing(0.5),
        bottom: theme.spacing(0.5),
        width: 30,
        height: 30
    }
})

type ClassKey = "root" | "commanderColor" | "donatorColor" | "starIcon" | "starIconContainer" | "oldDonatorIcon";
type PropsType = IProps & WithStyles<ClassKey>

const DonatorName: React.FC<PropsType> = (props) => {
    const { classes, user_id, name, country, donator, variant } = props;

    const rootClasses = [classes.root];
    if (user_id === 2330619) rootClasses.push(classes.commanderColor);
    if (donator) rootClasses.push(classes.donatorColor);

    const seed = Math.round(Math.random() * 100);

    const cupheadCount = user_id === 11495715 ? 1 : 0;

    return (
        <div className={clsx(...rootClasses)}>
            {country && <CountryFlagIcon country={country} />}
            <Typography variant={variant ?? "body2"}>{name}</Typography>
            {!!(donator && seed > 0) && (
                <span className={classes.starIconContainer}>
                    <BetterTooltip title={["Donator <3"]}>
                        <StarIcon className={classes.starIcon} />
                    </BetterTooltip>
                </span>
            )}
            {!!(donator && seed === 0) && (
                <span className={classes.starIconContainer}>
                    <BetterTooltip title={["Donator <3"]}>
                        <img className={classes.oldDonatorIcon} src={OldDonatorIcon} alt="" />
                    </BetterTooltip>
                </span>
            )}
            {!!cupheadCount && Array.from(Array(cupheadCount)).map(_ => (
                <span className={classes.starIconContainer}>
                    <BetterTooltip title={["cuphead"]}>
                        <img src={cuphead} className={classes.starIcon} alt="" />
                    </BetterTooltip>
                </span>
            ))}
        </div>
    );
}

export default withStyles(styles)(DonatorName)