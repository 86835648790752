import { ChartType, ChartOptions } from "chart.js";
import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import BaseChart from "../BaseChart/BaseChart";

interface IProps {
    labels: string[],
    datasets: { label: string, data: number[], backgroundColor: string }[]
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const BarChart: React.FC<PropsType> = (props) => {
    const { labels, datasets } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const chartType: ChartType = "bar";

    const options: ChartOptions = {
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: isSmallScreen
                    },
                    stacked: isSmallScreen
                }
            ],
            yAxes: [
                {
                    stacked: isSmallScreen
                }
            ]
        },
        legend: {
            display: false
        }
    };

    const data = {
        labels,
        datasets
    };

    return <BaseChart data={data} type={chartType} options={options} />;
}

export default withStyles(styles)(BarChart)