import React, { useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Tabs, Tab, Typography, Box, useMediaQuery, useTheme } from '@material-ui/core';
import ToolBar from '../../common/components/ToolBar/ToolBar';
import { useFetch } from '../../common/hooks/useAPICall';
import { SqlDetailedPlayer } from '../../common/interfaces/DetailedPlayer';
import { CountryContext, PlayerContext, GamemodeContext } from '../../common/context';
import { useLocation } from 'react-router-dom';
import StatisticsPanel from '../../common/components/StatisticsPanel/StatisticsPanel';
import { usePlayerDataRounder } from '../../common/hooks/usePlayerDataRounder';
import TopRanks from './components/TopRanks/TopRanks';
import PlayerDetailsCharts from './components/PlayerDetailsCharts/PlayerDetailsCharts';
import RecentChanges from './components/RecentChanges/RecentChanges';
import { convertGamemodeToString } from '../../common/helpers/generic-helpers';
import { formatPlayerRank } from '../../common/helpers/player-helpers';
import i18n from '../../i18n';
import { Gamemode } from '../../common/interfaces/Gamemode';
import LoadingIcon from '../../common/components/LoadingIcon/LoadingIcon';
import StyledName from '../../common/components/StyledName/StyledName';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    statisticsPanelContainer: {
        width: "calc(100% - 24px)",
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(2)
    },
    tabsContainer: {
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            display: "flex",
        }
    },
    tabs: {
        minWidth: "15%",
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.background.paper,
            width: "calc(100% - 24px)",
        },
        color: theme.palette.text.primary
    },
    tabPanel: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    donator: {
        color: "goldenrod"
    }
})

type ClassKey = 'root' | "statisticsPanelContainer" | "tabsContainer" | "tabs" | "tabPanel" | "donator";
type PropsType = IProps & WithStyles<ClassKey>

const PlayerDetails: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [selectedTab, setSelectedTab] = useState(0);
    const location = useLocation();
    const { response, loading } = useFetch<SqlDetailedPlayer>(`player/${country.code}/${location.pathname.split('/').pop()}?mode=${gamemodeString}`);
    const player = usePlayerDataRounder([response])[0];
    const theme = useTheme();
    const tabsOrientation = useMediaQuery(theme.breakpoints.up('md')) ? "vertical" : "horizontal";

    if (loading)
        return <LoadingIcon />;

    if (!response.user_id)
        return <>Player not found</>;

    const statisticsTiles = [
        { label: i18n.t("playerDetails:stats.count"), value: player.count_total.toLocaleString(), hover: `#${player.rank_count}` },
        { label: i18n.t("playerDetails:stats.weighted_pp"), value: `${player.weighted_pp.toLocaleString()}pp`, hover: formatPlayerRank(player.rank_weighted_pp) },
        { label: i18n.t("playerDetails:stats.average_pp"), value: `${player.average_pp}pp`, hover: formatPlayerRank(player.rank_average_pp) },
        { label: i18n.t("playerDetails:stats.average_sr"), value: `${player.average_sr.toLocaleString()}*`, hover: formatPlayerRank(player.rank_average_sr) },
        { label: i18n.t("playerDetails:stats.average_acc"), value: `${player.average_acc.toLocaleString()}%`, hover: formatPlayerRank(player.rank_average_acc) },
        { label: i18n.t("playerDetails:stats.total_score"), value: player.total_score.toLocaleString(), hover: formatPlayerRank(player.rank_total_score) },
        { label: i18n.t("playerDetails:stats.average_score"), value: player.average_score.toLocaleString(), hover: formatPlayerRank(player.rank_average_score) }
    ];

    const handleTabChange = (_: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    function TabPanel(props: any) {
        const { children, index } = props;
        return (
            <Typography className={classes.tabPanel} component="div" role="tabpanel" hidden={selectedTab !== index}>
                {selectedTab === index && <Box p={1.5}>{children}</Box>}
            </Typography>
        )
    };

    const handleNameClick = () => {
        window.open(`https://osu.ppy.sh/u/${player.user_id}`);
    };

    return (
        <PlayerContext.Provider value={player}>
            <ToolBar shortTitle={i18n.t("playerDetails:toolbar.shortTitle")} longTitle={i18n.t("playerDetails:toolbar.longTitle", { playerName: player.username })} />
            <div className={classes.statisticsPanelContainer}>
                <StatisticsPanel xs={1} sm={1} md={2} lg={1} mainComponent={<StyledName user_id={player.user_id} name={player.username} donator={player.is_donator} variant="h6" />} mainLabel={player.username} tiles={statisticsTiles} onMainClick={handleNameClick} />
            </div>
            <div className={classes.tabsContainer}>
                <Tabs indicatorColor="primary" orientation={tabsOrientation} variant="scrollable" value={selectedTab} onChange={handleTabChange} className={classes.tabs}>
                    <Tab label={i18n.t("playerDetails:tabs.charts")} />
                    {gamemode === Gamemode.Osu && <Tab label={i18n.t("playerDetails:tabs.recentActivity")} />}
                    <Tab label={i18n.t("playerDetails:tabs.topRanks")} />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <PlayerDetailsCharts />
                </TabPanel>
                {gamemode === Gamemode.Osu && <TabPanel value={selectedTab} index={1}>
                    <RecentChanges />
                </TabPanel>}
                <TabPanel value={selectedTab} index={gamemode === Gamemode.Osu ? 2 : 1}>
                    <TopRanks />
                </TabPanel>
            </div>
        </PlayerContext.Provider>
    )
}

export default withStyles(styles)(PlayerDetails)