import React, { useState, ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Button, Drawer, Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { Mods } from '../../interfaces/Mods';

interface IProps {
    selectedMods: Mods;
    update: (mods: Mods, newMod: keyof Mods, value: boolean) => void;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    formControl: {
        display: 'flex',
        justifyContent: 'space-around'
    }
})

type ClassKey = 'root' | 'formControl'
type PropsType = IProps & WithStyles<ClassKey>

const ModsSelectorDrawer: React.FC<PropsType> = (props) => {
    const { classes, selectedMods, update } = props;
    const [open, setOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
        setOpen(open);
    };

    const handleModsChange = (mod: keyof Mods) => (event: ChangeEvent<HTMLInputElement>) => {
        selectedMods[mod] = event.target.checked;
        update({ ...selectedMods }, mod, event.target.checked);
    };

    const { nomod, EZ, NF, HT, HD, HR, DT, NC, FL } = selectedMods;

    return (
        <>
            <Button variant='contained' color='primary' onClick={toggleDrawer(true)}>Filters</Button>
            <Drawer anchor='top' open={open} onClose={toggleDrawer(false)}>
                <Typography variant='h6'>
                    Select mods
                </Typography>
                <div className={classes.formControl}>
                    <FormControl component='fieldset'>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={nomod} onChange={handleModsChange('nomod')} value='nomod' />}
                                label='Nomod'
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={EZ} onChange={handleModsChange('EZ')} value='EZ' />}
                                label='Easy'
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={NF} onChange={handleModsChange('NF')} value='NF' />}
                                label='No Fail'
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={HT} onChange={handleModsChange('HT')} value='HT' />}
                                label='Halftime'
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component='fieldset'>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={HD} onChange={handleModsChange('HD')} value='HD' />}
                                label='Hidden'
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={HR} onChange={handleModsChange('HR')} value='HR' />}
                                label='Hardrock'
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={DT} onChange={handleModsChange('DT')} value='DT' />}
                                label='Double Time'
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={NC} onChange={handleModsChange('NC')} value='NC' />}
                                label='Nightcore'
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={FL} onChange={handleModsChange('FL')} value='FL' />}
                                label='Flashlight'
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </Drawer>
        </>
    )
}

export default withStyles(styles)(ModsSelectorDrawer)