import React, { useContext, useState, useEffect } from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { CountryContext, GamemodeContext, PlayerContext } from "../../../../common/context";
import { ParsedTopRank } from "../../../../common/interfaces/Beatmap";
import BaseTable from "../../../../common/components/BaseTable/BaseTable";
import { getTopRanksPage, parseTopRanks } from "../../player-details-helper";
import TableLinks from "../../../../common/components/OsuDirectLink/TableLinks";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../store";

interface IProps {
    topRanksCount: number;
    selectedColumns: { id: string, label: string, sortable?: boolean, maxWidth?: string }[];
    selectedMods: string;
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    osuDirectButton: {
        maxHeight: 0
    }
});

type ClassKey = "root" | "osuDirectButton";
type PropsType = IProps & WithStyles<ClassKey>;

const TopRanksTable: React.FC<PropsType> = (props) => {
    const { topRanksCount, selectedColumns, selectedMods } = props;
    const { settings } = useSelector((state: IApplicationState) => state);
    const country = useContext(CountryContext);
    const player = useContext(PlayerContext);
    const gamemode = useContext(GamemodeContext);

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pages, setPages] = useState<{ [key: number]: ParsedTopRank[] }>({});
    const [allTopRanks, setAllTopRanks] = useState<ParsedTopRank[]>([]);
    const [sortingField, setSortingField] = useState<keyof ParsedTopRank>("pp_label");
    const [sortingOrder, setSortingOrder] = useState<"asc" | "desc">("desc");

    useEffect(() => {
        if (!isFirstRender) {
            setPages({});
            setCurrentPage(0);
            initTopRanks();
        }
        // eslint-disable-next-line
    }, [sortingField, sortingOrder, selectedMods]);

    useEffect(() => {
        initTopRanks();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        const emptyPage = Array(50).fill({});
        const finalPage = Array(topRanksCount % 50).fill({});
        const numberOfPages = Math.ceil(topRanksCount / 50);
        const newTopRanks: ParsedTopRank[] = [];
        for (let i = 0; i < numberOfPages; i++) {
            if (!!pages[i])
                newTopRanks.push(...pages[i]);
            else
                i === numberOfPages - 1 ? newTopRanks.push(...finalPage) : newTopRanks.push(...emptyPage);
        }
        setAllTopRanks(newTopRanks);
    }, [pages, topRanksCount]);

    const initTopRanks = async () => {
        const topRanks = await getTopRanksPage(country.code, player.user_id, selectedMods, gamemode, convertKeyToSortField(sortingField), sortingOrder);
        const parsedTopRanks = parseTopRanks(topRanks, country.code, 0, settings);
        setPages({ 0: parsedTopRanks });
    };

    type ParsedTopRankBeatmapKey = `beatmap.${keyof ParsedTopRank["beatmap"]}`;
    const convertKeyToSortField = (key: keyof ParsedTopRank): keyof ParsedTopRank | ParsedTopRankBeatmapKey => {
        switch (key) {
            case "sr_label": return `sr`;
            case "pp_label": return "pp";
            case "accuracy_label": return `accuracy`;
            case "map_name": return "beatmap.artist";
            case "date_set_label": return "date_set";
            case "date_ranked_label": return "date_ranked";
            case "total_length_label": return "total_length";
            default: return key;
        };
    };

    const headers: any[] = selectedColumns.map(c => ({ key: c.id, value: c.label, sortable: c.sortable, maxWidth: c.maxWidth }));
    const osuDirectHeader = headers.find(h => h.key === "links");
    if (osuDirectHeader) osuDirectHeader.value = "";

    const rows = allTopRanks.map(topRank => ({
        key: topRank.rank,
        values: {
            ...topRank,
            mods: topRank.mods === "nomod" ? "" : topRank.mods,
            links: <TableLinks osuDirect mapId={topRank?.beatmap?.map_id} />
        },
        style: settings.highlightGlobalTopScores && country.code !== "global" && topRank.is_global_topscore ? { backgroundColor: "#b06934" } : {}
    }));

    const getNextPage = async () => {
        const currentPageData = pages[currentPage];
        const scoreToCompare = currentPageData[currentPageData.length - 1];
        if (!scoreToCompare) return;

        const newPage = currentPage + 1;
        const convertedSortingField = convertKeyToSortField(sortingField);
        const topranks = await getTopRanksPage(country.code, player.user_id, selectedMods, gamemode, convertedSortingField, sortingOrder, "next", scoreToCompare.uid);
        const parsedTopRanks = parseTopRanks(topranks, country.code, newPage, settings);
        setPages(p => ({ ...p, [newPage]: parsedTopRanks }));
        setCurrentPage(newPage);
    };

    const getPreviousPage = async () => {
        const scoreToCompare = pages[currentPage][0];
        if (!scoreToCompare) return;

        const newPage = currentPage - 1;
        const convertedSortingField = convertKeyToSortField(sortingField);
        const topranks = await getTopRanksPage(country.code, player.user_id, selectedMods, gamemode, convertedSortingField, sortingOrder, "prev", scoreToCompare.uid);
        const parsedTopRanks = parseTopRanks(topranks, country.code, newPage, settings);
        setPages(p => ({ ...p, [newPage]: parsedTopRanks }));
        setCurrentPage(newPage);
    };

    const handleRowClick = (row: ParsedTopRank): void => {
        window.open(`https://osu.ppy.sh/b/${row.beatmap.map_id}`);
    };

    const handlePageChange = (page: number) => {
        if (!pages[page]) {
            if (currentPage < page) getNextPage();
            else getPreviousPage();
        }
        else
            setCurrentPage(page);
    };

    const handleSortingFieldChange = (newSortingField: keyof ParsedTopRank) => {
        const isDesc = newSortingField === sortingField && sortingOrder === "desc";
        setSortingField(newSortingField);
        setSortingOrder(isDesc ? "asc" : "desc");
    };

    return (
        <BaseTable
            headers={headers}
            rows={rows}
            handleClick={handleRowClick}
            currentPage={currentPage}
            handlePageNumberChange={handlePageChange}
            sortBy={sortingField}
            sortingHandler={handleSortingFieldChange}
            order={sortingOrder}
        />
    );
};

export default withStyles(styles)(TopRanksTable);