import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { ChartOptions, ChartType } from 'chart.js';
import BaseChart from '../BaseChart/BaseChart';

interface IProps {
    labels: string[],
    datasets: { data: number[], backgroundColor?: string }[]
};

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
});

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const RadarChart: React.FC<PropsType> = (props) => {
    const { labels, datasets } = props;
    const chartType: ChartType = "radar";

    const options: ChartOptions = {

        legend: {
            display: false
        },
        maintainAspectRatio: false,
        scale: {
            pointLabels: {
                fontSize: 12,
            },
            ticks: {
                suggestedMin: 0,
                suggestedMax: 50,
                stepSize: 10,
                showLabelBackdrop: false,
                callback: (value, index, values) => {
                    const rank = 250 - (+value * 5);
                    return `#${rank === 0 ? 1 : rank}`;
                }
            }
        },
        tooltips: {
            callbacks: {
                title: () => "",
                label: (tooltipItem, data) => {
                    if (tooltipItem.index === undefined || tooltipItem.datasetIndex === undefined || !data.labels || !data.datasets)
                        return "";
                    const label = data.labels[tooltipItem.index];
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const rank = (dataset as any).ranks[tooltipItem.index];
                    return `${label}: #${rank}`;
                }
            }
        }
    };

    const data = {
        labels,
        datasets
    };

    return <BaseChart data={data} type={chartType} options={options} />
};

export default withStyles(styles)(RadarChart)