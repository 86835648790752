import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { ChartType, ChartOptions } from 'chart.js';
import BaseChart from '../BaseChart/BaseChart';

interface IProps {
    labels: string[],
    datasets: { data: number[], backgroundColor?: string[] }[],
    showLabels: boolean;
    onClick?: (event: any, chart: Chart) => void;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const PieChart: React.FC<PropsType> = (props) => {
    const { labels, datasets, showLabels, onClick } = props;
    const chartType: ChartType = "pie";

    const options: ChartOptions = {
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                title: (tooltipItems, data): string => {
                    if (!data?.datasets) return "";
                    return data.datasets[tooltipItems[0]?.datasetIndex || 0].label || "";
                },
                label: (tooltipItem, data): string => {
                    if (tooltipItem.index === undefined || tooltipItem.datasetIndex === undefined || !data.labels || !data.datasets)
                        return "";

                    const label = (data.datasets[tooltipItem.datasetIndex] as any).labels[tooltipItem.index];
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    if (!dataset.data) return "";

                    const value = dataset.data[tooltipItem.index] as number;
                    const total = (dataset.data as number[]).reduce((total: number, current: number) => total + current, 0);
                    const percentage = total > 0 ? Math.round(value / total * 10000) / 100 : 0;

                    return `${label}: ${percentage}% (${value})`;
                }
            }
        },
        cutoutPercentage: 50,
        plugins: {
            datalabels: {
                backgroundColor: function (context: any) {
                    return context.dataset.backgroundColor;
                },
                borderColor: 'white',
                borderRadius: 25,
                borderWidth: 2,
                color: 'white',
                display: function (context: any) {
                    const dataset = context.dataset;
                    const total = context.dataset.data.reduce((total: number, current: number) => total + current, 0);
                    const value = dataset.data[context.dataIndex];
                    const share = total > 0 ? value / total : 0;
                    return share >= 0.035;
                },
                formatter: (value: any, context: any) => {
                    if (!showLabels) return null;
                    return context.chart.data.labels[context.dataIndex];
                },
                font: {
                    weight: 'bold'
                },
                padding: 6,
            }
        },
    };

    const data = {
        labels,
        datasets
    };

    return <BaseChart data={data} type={chartType} options={options} onClick={onClick} />;
};

export default withStyles(styles)(PieChart)