import React, { ChangeEvent, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, FormControl, InputLabel, Select, Checkbox, ListItemText, MenuItem, Input } from '@material-ui/core';

interface IProps {
    label: string;
    items: { id: string, label: string, default?: boolean, disabled?: boolean }[];
    handleValueChange: (newValues: string[]) => void;
    selectBoxStyling: any;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    selectBox: {
        textAlign: "left"
    }
});

type ClassKey = "root" | "selectBox";
type PropsType = IProps & WithStyles<ClassKey>

const MultiSelectBox: React.FC<PropsType> = (props) => {
    const { classes, label, items, handleValueChange, selectBoxStyling } = props;
    const defaultItems = items.filter(i => i.default);
    const [values, setValues] = useState<string[]>(defaultItems.map(i => i.id));

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setValues(event.target.value as string[]);
        handleValueChange(event.target.value as string[]);
    };

    return (
        <FormControl className={selectBoxStyling}>
            <InputLabel>{label}</InputLabel>
            <Select className={classes.selectBox} value={values} onChange={handleChange} renderValue={() => `${values.length} selected`} input={<Input />} multiple>
                {items.map(item => (
                    <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
                        <Checkbox checked={values.indexOf(item.id) > -1} disabled={item.disabled} color="primary" />
                        <ListItemText primary={item.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default withStyles(styles)(MultiSelectBox)