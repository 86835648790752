import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, AppBar, Toolbar, Typography } from '@material-ui/core';
import DiscordButton from '../DiscordButton/DiscordButton';
import SideBar from '../SideBar/SideBar';
import MobileOnly from '../MobileHelpers/MobileOnly';
import NoMobile from '../MobileHelpers/NoMobile';

interface IProps {
    shortTitle: string;
    longTitle: string;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        height: 64
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        backgroundColor: theme.palette.primary.main
    },
    toolbarStart: {
        display: 'flex',
        alignItems: 'center'
    },
    toolbarEnd: {

    },
    titleBarIcon: {
        fontSize: '30px'
    }
})

type ClassKey = 'root' | 'toolbar' | 'toolbarStart' | 'toolbarEnd' | 'titleBarIcon'
type PropsType = IProps & WithStyles<ClassKey>

const ToolBar: React.FC<PropsType> = (props) => {
    const { classes, shortTitle, longTitle } = props;

    return (
        <AppBar className={classes.root} position="static">
            <Toolbar className={classes.toolbar}>
                <div className={classes.toolbarStart}>
                    <SideBar />
                    <Typography variant="h6">
                        <NoMobile>{longTitle}</NoMobile>
                        <MobileOnly>{shortTitle}</MobileOnly>
                    </Typography>
                </div>
                <DiscordButton />
            </Toolbar>
        </AppBar>
    )
}

export default withStyles(styles)(ToolBar)