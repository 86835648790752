import React, { useState, MouseEvent, useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Mods } from '../../interfaces/Mods';

interface IProps {
    selectedMods: Mods;
    update: (mods: Mods, newMod: keyof Mods, value: boolean) => void;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const ModsSelectorButtons: React.FC<PropsType> = (props) => {
    const { selectedMods, update } = props;
    const [mods, setMods] = useState<string[]>([]);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        const newMods = Object.keys(selectedMods).filter(key => selectedMods[key as keyof Mods]);
        setMods(newMods);
    }, [selectedMods]);

    const handleChange = (mod: keyof Mods) => (_: MouseEvent) => {
        const newValue = !selectedMods[mod];
        selectedMods[mod] = newValue;
        update({ ...selectedMods }, mod, newValue);
    };

    return (
        <ToggleButtonGroup value={mods}>
            <ToggleButton value='nomod' onClick={handleChange('nomod')}>Nomod</ToggleButton>
            <ToggleButton value='EZ' onClick={handleChange('EZ')}>{isLargeScreen ? 'Easy' : 'EZ'}</ToggleButton>
            <ToggleButton value='NF' onClick={handleChange('NF')}>{isLargeScreen ? 'No Fail' : 'NF'}</ToggleButton>
            <ToggleButton value='HT' onClick={handleChange('HT')}>{isLargeScreen ? 'Halftime' : 'HT'}</ToggleButton>
            <ToggleButton value='HD' onClick={handleChange('HD')}>{isLargeScreen ? 'Hidden' : 'HD'}</ToggleButton>
            <ToggleButton value='HR' onClick={handleChange('HR')}>{isLargeScreen ? 'Hardrock' : 'HR'}</ToggleButton>
            <ToggleButton value='DT' onClick={handleChange('DT')}>{isLargeScreen ? 'Double Time' : 'DT'}</ToggleButton>
            <ToggleButton value='NC' onClick={handleChange('NC')}>{isLargeScreen ? 'Nightcore' : 'NC'}</ToggleButton>
            <ToggleButton value='FL' onClick={handleChange('FL')}>{isLargeScreen ? 'Flashlight' : 'FL'}</ToggleButton>
        </ToggleButtonGroup>
    )
}

export default withStyles(styles)(ModsSelectorButtons)