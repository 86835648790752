import { Chart, ChartType, ChartOptions } from "chart.js";
import React, { useRef, useState, useEffect } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface IProps {
    type: ChartType,
    data: any;
    options?: ChartOptions;
    onClick?: (event: any, chart: Chart) => void;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {}
})

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const BaseChart: React.FC<PropsType> = (props) => {
    const { type, data, options, onClick } = props;
    const chartRef = useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart>();

    // Create initial chart
    useEffect(() => {
        if (chartRef?.current) {
            Chart.plugins.unregister(ChartDataLabels);
            const plugins = type === "pie" ? [ChartDataLabels] : [];
            const newChart = new Chart(chartRef.current, { type, plugins });
            setChart(newChart);
        }
    }, [chartRef, type]);

    // Data changed
    useEffect(() => {
        if (chart) {
            chart.data.labels = data.labels;
            chart.data.datasets = data.datasets;
            if (options)
                chart.options = options;
            if (onClick)
                chart.options.onClick = (event) => onClick(event, chart);
            chart.update();
        }
    }, [chart, data, options, onClick]);

    return <canvas ref={chartRef} />;
}

export default withStyles(styles)(BaseChart)