import React, { useContext, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, List, ListItem, ListItemText, Collapse, Box, FormControl, InputLabel, Select, MenuItem, Divider } from '@material-ui/core';
import { CountryContext } from '../../../../../common/context';
import { useFetch } from '../../../../../common/hooks/useAPICall';
import { SniperSummary, SqlSnipesRankings, SqlTarget } from '../../../../../common/interfaces/SnipeTarget';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from "clsx";
import SnipesRankingsTable from './SnipesRankingsTable';
import NoMobile from '../../../../../common/components/MobileHelpers/NoMobile';
import MobileOnly from '../../../../../common/components/MobileHelpers/MobileOnly';
import LoadingIcon from '../../../../../common/components/LoadingIcon/LoadingIcon';
import _ from 'lodash';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    tabsContainer: {
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            display: "flex",
        },
        color: theme.palette.text.primary
    },
    sidebar: {
        display: "flex",
        flexDirection: "column",
        minWidth: "15%"
    },
    nestedItem: {
        paddingLeft: theme.spacing(4)
    },
    selectedItem: {
        borderRightWidth: 3,
        borderRightStyle: "solid",
        borderRightColor: theme.palette.primary.main
    },
    snipesPanel: {
        width: "100%",
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3)
        }
    },
    targetSelectBox: {
        textAlign: "left",
        width: "90%",
        marginLeft: "5%",
        marginTop: theme.spacing(1)
    }
})

type ClassKey = 'root' | "tabsContainer" | "sidebar" | "nestedItem" | "selectedItem" | "snipesPanel" | "targetSelectBox";
type PropsType = IProps & WithStyles<ClassKey>

const SnipesRankings: React.FC<PropsType> = (props) => {
    const { classes } = props;
    const country = useContext(CountryContext);
    const { response: targets, loading } = useFetch<SqlTarget[]>(`target/all/${country.code}`);
    const { response: allSnipesStats, loading: loadingSnipesStats } = useFetch<SqlSnipesRankings[]>(`rankings/${country.code}/snipes/all`);

    const [selectedTarget, setSelectedTarget] = useState<number>();
    const [activeTargetsOpen, setActiveTargetsOpen] = useState(false);
    const [inactiveTargetsOpen, setInactiveTargetsOpen] = useState(false);

    if (loading || loadingSnipesStats)
        return <LoadingIcon />;

    const activeTargets = _.uniqBy(targets.filter(target => target.is_enabled), "target_id").sort((a, b) => a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1);
    const inactiveTargets = _.uniqBy(targets.filter(target => !target.is_enabled), "target_id").sort((a, b) => a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1);

    const sniperSummaries: SniperSummary[] = [];
    allSnipesStats.forEach(s => {
        const summary = sniperSummaries.find(ss => ss.user_id === s.user_id);
        if (summary) summary.total_snipes += s.snipe_count;
        else sniperSummaries.push({ user_id: s.user_id, name: s.username, total_snipes: s.snipe_count });
    });

    const handleSelectTarget = (targetId: number) => {
        const target = targets.find(t => t.target_id === targetId);
        if (target)
            setSelectedTarget(target.user_id);
    };

    const handleSelectAllTargetRankings = () => {
        setSelectedTarget(-1);
    };

    const collapseActiveTargets = (): void => {
        setActiveTargetsOpen(!activeTargetsOpen);
    };

    const collapseInactiveTargets = (): void => {
        setInactiveTargetsOpen(!inactiveTargetsOpen);
    };

    if (!selectedTarget && activeTargets.length)
        handleSelectAllTargetRankings();

    return (
        <div className={classes.tabsContainer}>
            <div className={classes.sidebar}>
                <NoMobile>
                    <List>
                        <ListItem
                            className={selectedTarget === -1 ? classes.selectedItem : ""}
                            button onClick={handleSelectAllTargetRankings}
                        >
                            <ListItemText primary="Total rankings" />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={collapseActiveTargets}>
                            <ListItemText primary="Active targets" />
                            {activeTargetsOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={activeTargetsOpen} timeout="auto" unmountOnExit>
                            {activeTargets.map(target => (
                                <ListItem
                                    className={selectedTarget === target.target_id ? clsx(classes.nestedItem, classes.selectedItem) : classes.nestedItem}
                                    key={target.target_id}
                                    button onClick={() => handleSelectTarget(target.target_id)}
                                >
                                    <ListItemText primary={target.username} />
                                </ListItem>
                            ))}
                        </Collapse>
                        <ListItem button onClick={collapseInactiveTargets}>
                            <ListItemText primary="Inactive targets" />
                            {inactiveTargetsOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={inactiveTargetsOpen} timeout="auto" unmountOnExit>
                            {inactiveTargets.map(target => (
                                <ListItem
                                    className={selectedTarget === target.target_id ? clsx(classes.nestedItem, classes.selectedItem) : classes.nestedItem}
                                    key={target.target_id}
                                    button onClick={() => handleSelectTarget(target.target_id)}
                                >
                                    <ListItemText primary={target.username} />
                                </ListItem>
                            ))}
                        </Collapse>
                    </List>
                </NoMobile>
                <MobileOnly>
                    <FormControl className={classes.targetSelectBox}>
                        <InputLabel>Target</InputLabel>
                        <Select value={selectedTarget} onChange={(event) => handleSelectTarget(event.target.value as number)}>
                            {activeTargets.map(target => (
                                <MenuItem key={target.target_id} value={target.target_id}>{target.username}</MenuItem>
                            ))}
                            {inactiveTargets.map(target => (
                                <MenuItem key={target.target_id} value={target.target_id}>{target.username}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </MobileOnly>
            </div>

            <Box className={classes.snipesPanel}>
                {selectedTarget && <SnipesRankingsTable targetId={selectedTarget} targetSnipes={allSnipesStats.filter(s => s.target_id === selectedTarget || selectedTarget === -1)} summaries={sniperSummaries} />}
            </Box>
        </div>
    )
}

export default withStyles(styles)(SnipesRankings)