import config from "../../common/config";
import { convertDateFormat, convertGamemodeToString } from "../../common/helpers/generic-helpers";
import { BeatmapRankedStatus, ParsedTopRank, SqlTopRank } from "../../common/interfaces/Beatmap";
import { Gamemode } from "../../common/interfaces/Gamemode";
import { Mod, ModsData } from "../../common/interfaces/Mods";
import moment from "moment";
import { Settings } from "../../common/interfaces/Settings";
import { round } from "lodash";

export interface DifficultyAttributes {
    ar: number;
    cs: number;
    od: number;
    hp: number;
};

export const combineMods = (modsData: ModsData[], oldMod: string, newMod: string): void => {
    modsData.forEach(modCombination => {
        if (modCombination.name.includes(oldMod)) {
            const newName = modCombination.name.replace(oldMod, newMod);
            const oldModsData = modsData.find(m => m.name === newName);
            oldModsData ? oldModsData.value += modCombination.value : modsData.push({ name: newName, value: modCombination.value });
            modCombination.value = 0;
        }
    });
};

export const ignoreMods = (modsData: ModsData[], modsToIgnore: string[]): void => {
    modsData.forEach(modCombination => {
        let newName = modsToIgnore.reduce((total, current) => total.replace(current, ""), modCombination.name);
        if (newName === "") newName = "nomod";
        if (newName !== modCombination.name) {
            const existingModsData = modsData.find(m => m.name === newName);
            existingModsData ? existingModsData.value += modCombination.value : modsData.push({ name: newName, value: modCombination.value });
            modCombination.value = 0;
        }
    });
};

export const getTopRanksPage = async (country: string, player_id: number, mods: string, gamemode: Gamemode, sortingField: string, order: "asc" | "desc", page?: "next" | "prev", uid?: number): Promise<SqlTopRank[]> => {
    let url = `${config.api}/player/${country}/${player_id}/topranks?mods=${mods}&mode=${convertGamemodeToString(gamemode)}&sort=${sortingField}&order=${order}`;
    if (page)
        url = url.concat(`&page=${page}&uid=${uid}`);

    const res = await fetch(url, {
        method: "GET",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json"
        })
    });
    return await res.json();
};

export const parseTopRanks = (topRanks: SqlTopRank[], country: string, pageNumber: number, settings: Settings): ParsedTopRank[] => {
    if (topRanks.length) {
        return topRanks.map((topRank, index) => {
            const t = { ...topRank };
            const total_pp = Math.round(t.pp);

            const map_name = `${t.beatmap.artist} - ${t.beatmap.title} [${t.beatmap.diff_name}]`;
            const rank = pageNumber * 50 + index + 1;
            const weighted_pp = Math.round(t.pp * Math.pow(0.95, index));
            const accuracy_label = `${+(t.accuracy * 100).toFixed(2)}%`;

            const { date_set } = t;
            const dateFormat = convertDateFormat(settings.dateFormat);

            const minutes = Math.floor(topRank.total_length / 60);

            const parsedTopRank: ParsedTopRank = {
                ...t,
                rank,
                map_name,
                sr_label: `${round(t.sr, 2)}*`,
                accuracy_label,
                pp_label: t.beatmap.ranked_status === BeatmapRankedStatus.LOVED ? "-" : `${total_pp} (${weighted_pp})`,
                weighted_pp,
                date_set_label: moment(date_set).format(dateFormat),
                date_ranked_label: moment(t.date_ranked).format(dateFormat),
                is_global_topscore: t.is_global,
                total_length_label: minutes > 0 ? `${minutes}m${topRank.total_length - (minutes * 60)}s` : `${topRank.total_length}s`
            };

            return parsedTopRank;
        });
    }
    return [];
};

export const convertDifficultyAttributes = (ar: number, cs: number, od: number, hp: number, mods: string): DifficultyAttributes => {
    if (mods.includes(Mod.Hardrock)) {
        ar = Math.min(ar * 1.4, 10);
        cs = Math.min(cs * 1.3, 10);
        od = Math.min(od * 1.4, 10);
        hp = Math.min(hp * 1.4, 10);
    }
    else if (mods.includes(Mod.Easy)) {
        ar *= 0.5;
        cs *= 0.5;
        od *= 0.5;
        hp *= 0.5;
    }

    if (mods.includes(Mod.DoubleTime) || mods.includes(Mod.Nightcore)) {
        const ms = convertApproachRateToMilliseconds(ar) * (2 / 3);
        ar = convertMillisecondsToApproachRate(ms);

        const hitWindow = convertOverallDifficultyToHitWindow(od) * (2 / 3);
        od = convertHitWindowToOverallDifficulty(hitWindow);
    }
    else if (mods.includes(Mod.HalfTime)) {
        const ms = convertApproachRateToMilliseconds(ar) / (3 / 4);
        ar = convertMillisecondsToApproachRate(ms);
        const hitWindow = convertOverallDifficultyToHitWindow(od) / (3 / 4);
        od = convertHitWindowToOverallDifficulty(hitWindow);
    }

    return {
        ar: +ar.toFixed(2),
        cs: +cs.toFixed(2),
        od: +od.toFixed(2),
        hp: +hp.toFixed(2),
    };
};

export const calculateBPM = (bpm: number, mods: string): number => {
    if (mods.includes(Mod.DoubleTime) || mods.includes(Mod.Nightcore))
        return bpm * 1.5;
    if (mods.includes(Mod.HalfTime))
        return bpm * 0.75;
    return bpm;
};

const convertApproachRateToMilliseconds = (ar: number): number => {
    return ar <= 5
        ? 1800 - (ar * 120)
        : 1200 - ((ar - 5) * 150);
};

const convertMillisecondsToApproachRate = (ms: number): number => {
    return ms > 1200
        ? (1800 - ms) / 120
        : (1200 - ms) / 150 + 5;
};

const convertOverallDifficultyToHitWindow = (od: number): number => {
    return 79.5 - (od * 6);
};

const convertHitWindowToOverallDifficulty = (hitWindow: number): number => {
    return (79.6 - hitWindow) / 6;
};