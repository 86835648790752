import { Country } from "../common/interfaces/Country";
import { Settings } from "../common/interfaces/Settings";
import { countriesReducer } from "./countries/reducer";
import { settingsReducer } from "./settings/reducer";

export const reducers = {
    settings: settingsReducer,
    countries: countriesReducer
};

export interface IAppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => IApplicationState): void;
};

export interface IApplicationState {
    settings: Settings;
    countries: Country[];
};
