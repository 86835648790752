export enum Mod {
    Nomod = "nomod",
    Easy = "EZ",
    NoFail = "NF",
    HalfTime = "HT",
    Hardrock = "HR",
    SuddenDeath = "SD",
    Perfect = "PF",
    DoubleTime = "DT",
    Nightcore = "NC",
    Hidden = "HD",
    Flashlight = "FL",
    SpunOut = "SO"
};

export interface Mods {
    nomod: boolean,
    EZ: boolean,
    NF: boolean,
    HT: boolean,
    HD: boolean,
    HR: boolean,
    DT: boolean,
    NC: boolean,
    FL: boolean
}

export interface ModsData {
    name: string,
    value: number
};