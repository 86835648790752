import React, { useState, } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import Searchbar from '../../../../common/components/Searchbar/Searchbar';
import ModsSelector from '../../../../common/components/ModsSelector/ModsSelector';
import { Mods } from '../../../../common/interfaces/Mods';

interface IProps {
    showSearchBar?: boolean;
    showModsSelector?: boolean;
    update: (searchValue: string | null, mods: Mods | null) => void;
    searchDelay?: number;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            paddingLeft: '5%'
        }
    },
    searchbar: {
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        },
        maxHeight: '48px'
    },
    dialogAppBar: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative"
    }
})

type ClassKey = 'root' | 'searchbar' | 'dialogAppBar';
type PropsType = IProps & WithStyles<ClassKey>

const RankingsFilters: React.FC<PropsType> = (props) => {
    const { classes, showSearchBar, showModsSelector, update, searchDelay } = props;
    const [searchValue, setSearchValue] = useState('');
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

    const handleSearch = (value: string) => {
        setSearchValue(value);
        if (searchTimeout) clearTimeout(searchTimeout);
        setSearchTimeout(setTimeout(() => {
            update(value, null);
            setSearchTimeout(undefined);
        }, searchDelay || 0));
    };

    const handleModsSelectionChange = (mods: Mods) => {
        update(null, mods);
    };

    return (
        <>
            <div className={classes.root}>
                {showSearchBar && <Searchbar styling={classes.searchbar} label="Search player" value={searchValue} update={handleSearch} />}
                {/* {searchTimeout && <CircularProgress />} */}
                {showModsSelector && <ModsSelector update={handleModsSelectionChange} shouldApply />}
            </div>
        </>
    );
}

export default withStyles(styles)(RankingsFilters)