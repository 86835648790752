import React from 'react';
import { withStyles, WithStyles, createStyles, Theme, ListItem, Typography } from '@material-ui/core';

interface IProps {
    label: string;
    value: string;
    addition?: string;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    label: {
        fontSize: '16px',
        color: 'grey'
    },
    value: {
        marginLeft: theme.spacing(1),
        fontSize: '16px'
    },
})

type ClassKey = 'root' | "label" | "value";
type PropsType = IProps & WithStyles<ClassKey>

const StatisticsListItem: React.FC<PropsType> = (props) => {
    const { classes, label, value, addition } = props;

    return (
        <ListItem>
            <Typography variant="body1" className={classes.label}>{label}:</Typography>
            <Typography variant="body1" className={classes.value}>{value} {!!addition && `(${addition})`}</Typography>
        </ListItem>
    );
}

export default withStyles(styles)(StatisticsListItem)