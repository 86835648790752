import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme, AppBar, Tabs, Tab } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface IProps {
    parentRoute: string;
    tabs: { id: string, label: string }[];
    defaultValue?: string;
}

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    tabs: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary
    }
})

type ClassKey = 'root' | 'tabs'
type PropsType = IProps & WithStyles<ClassKey>

function LinkTab(props: any) {
    const { path, linkToTab, ...others } = props;
    return (
        <Tab
            component="a"
            onClick={(event: any) => {
                event.preventDefault();
                linkToTab(path);
            }}
            {...others}
        />
    )
}

const LinkTabs: React.FC<PropsType> = (props) => {
    const { classes, parentRoute, tabs, defaultValue } = props;
    const [tabValue, setTabValue] = useState<number>();
    const history = useHistory();

    const tabIndex = tabs.findIndex(tab => tab.id === defaultValue);
    if (tabIndex !== tabValue && tabIndex > -1)
        setTabValue(tabIndex);

    const handleChange = (event: any, newValue: number) => {
        setTabValue(newValue);
    };

    const linkToTab = (path: string) => {
        const link = parentRoute + path;
        history.push(link);
    };

    return (
        <>
            <AppBar position="static">
                <Tabs className={classes.tabs} variant="scrollable" value={tabValue} onChange={handleChange} aria-label="link tabs" indicatorColor='primary' scrollButtons='auto'>
                    {tabs.map(tab => (
                        <LinkTab key={tab.id} label={tab.label} path={`/${tab.id}`} linkToTab={linkToTab} />
                    ))}
                </Tabs>
            </AppBar>
        </>
    )
}

export default withStyles(styles)(LinkTabs)