import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import Icon from '@iconify/react';
import discordIcon from '@iconify/icons-mdi/discord';

interface IProps { }

const styles = (theme: Theme) => createStyles<ClassKey, {}>({
    root: {},
    icon: {
        fontSize: '35px'
    }
})

type ClassKey = 'root' | 'icon'
type PropsType = IProps & WithStyles<ClassKey>

const DiscordButton: React.FC<PropsType> = (props) => {
    const { classes } = props;
    return (
        <span onClick={() => window.open("https://discordapp.com/invite/ExMDcT5")}>
            <Icon className={classes.icon} icon={discordIcon} />
        </span>
    )
}

export default withStyles(styles)(DiscordButton)