import React, { useContext, useState } from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { useFetch } from "../../../../common/hooks/useAPICall";
import { CountryContext, GamemodeContext } from "../../../../common/context";
import { usePlayerDataRounder } from "../../../../common/hooks/usePlayerDataRounder";
import RankingsTable from "../RankingsTable/RankingsTable";
import { convertGamemodeToString } from "../../../../common/helpers/generic-helpers";
import LoadingIcon from "../../../../common/components/LoadingIcon/LoadingIcon";
import RankingsFilters from "../RankingsFilters/RankingsFilters";
import StyledName from "../../../../common/components/StyledName/StyledName";
import { RankingsPlayerAverageAcc } from "../../../../common/interfaces/RankingsPlayer";

interface IProps { }

const styles = (theme: Theme) =>
    createStyles<ClassKey, {}>({
        root: {}
    });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const AverageAccuracyRankings: React.FC<PropsType> = props => {
    const country = useContext(CountryContext);
    const gamemode = useContext(GamemodeContext);
    const gamemodeString = convertGamemodeToString(gamemode);
    const [searchValue, setSearchValue] = useState<string>('');
    const { response: rankings, loading } = useFetch<RankingsPlayerAverageAcc[]>(`rankings/${country.code}/accuracy/average?mode=${gamemodeString}&search=${searchValue}`);
    const parsedRankings = usePlayerDataRounder(rankings);

    if (loading && !rankings)
        return <LoadingIcon />;

    const headers = [
        { key: country.code === "all" ? "rank_average_accuracy_combined" : "rank", value: 'Rank' },
        { key: 'name', value: 'Name' },
        { key: 'average_acc', value: 'Average accuracy' },
        { key: 'count_total', value: '#1 count' },
        { key: 'weighted_pp', value: 'Weighted pp' }
    ];

    const rows = parsedRankings.map((player, i) => ({
        key: player.user_id,
        values: {
            ...player,
            name: <StyledName user_id={player.user_id} name={player.username} donator={player.is_donator} country={country.code === "all" ? player.country : undefined} />,
            country: player.country?.toUpperCase() || "",
            rank: player.player_rank,
            rank_average_accuracy_combined: i + 1
        }
    }));

    const update = (newSearchValue: string | null) => {
        if (newSearchValue !== null) setSearchValue(newSearchValue);
    };

    return (
        <>
            <RankingsFilters update={update} showSearchBar searchDelay={500} />
            <RankingsTable headers={headers} rows={rows} mainField='average_accuracy' loading={loading} />
        </>
    )
};

export default withStyles(styles)(AverageAccuracyRankings);
